.footer {
    background-color: map-deep-get($colors, "red", "default");
    color: map-deep-get($colors, "white", "default");
    position: relative;
    z-index: 4;

    ::selection {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "red", "default");
    }

    &::before {
        content: "";
        background-color: map-deep-get($colors, "red", "default");
        height: rem-calc(250);
        top: rem-calc(-100);
        width: 140%;
        position: absolute;
        left: -20%;
        z-index: 1;
        transform: rotate(-15deg);

        @include media-breakpoint-up(md) {
            height: rem-calc(350);
            top: rem-calc(-150);
        }
        @include media-breakpoint-up(lg) {
            left: -10%;
            width: 120%;
            height: rem-calc(550);
            top: rem-calc(-300);
        }
    }

    &::after {
        content: "";
        background-color: map-deep-get($colors, "red", "default");
        position: absolute;
        left: 0;
        right: 0;
        bottom: -190px;
        height: 200px;
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__input {
        max-width: rem-calc(450);
    }

    &__title {
        padding-top: rem-calc(80);

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }

    &__top {
        padding-bottom: rem-calc(40);
    }

    &__social-list {
        margin-top: rem-calc(30);
    }

    &__social-list-link {
        margin-right: rem-calc(10);

        svg {
            fill: map-deep-get($colors, "white", "default");
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }

    &__bottom {
        border-top: 1px solid rgba(map-deep-get($colors, "white", "default"), .2);
        padding: 24px 0;
        font-size: rem-calc(14);
        display: flex;
        justify-content: space-between;
    }

    &__webnl {
        color: map-deep-get($colors, "white", "default");

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__list {
        margin: rem-calc(48) rem-calc(-10) 0;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            column-count: 2;
        }

        @include media-breakpoint-up(xl) {
            column-count: 3;
        }
    }

    &__list-item {
        width: 100%;
        padding: 0 rem-calc(10);
        //float: left;
        margin-bottom: rem-calc(16);

        //@include media-breakpoint-up(md) {
        //    width: 50%;
        //}

        //@include media-breakpoint-up(xl) {
        //    width: 33%;
        //}
    }

    &__list-link {
        display: flex;
        color: map-deep-get($colors, "white", "default");

        svg {
            width: rem-calc(30);
            height: rem-calc(18);
            fill: map-deep-get($colors, "black", "default");
            margin-right: rem-calc(12);
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__magazine {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(sm) {
            margin-top: rem-calc(20);
            flex-wrap: nowrap;
        }

        /* stylelint-disable */
        &-request {
            margin-top: 1rem;
        }
        /* stylelint-enable */
    }

    &__magazine-content {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }

    &__magazine-image {
        //width: rem-calc(200);
        height: 200px;
        margin-top: rem-calc(30);

        @include media-breakpoint-up(sm) {
            padding-left: 30px;
            margin-top: 0;
        }
    }

    /* stylelint-disable */
    .checkbox-wrapper {
        a {
            color: map-deep-get($colors, "white", "default");
        }
    }

    .form__invalid-message {
        color: map-deep-get($colors, "white", "default");
    }
    /* stylelint-enable */

    &__form-message {
        border: 1px solid map-deep-get($colors, "white", "default");
    }
}
