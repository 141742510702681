.office-streetview {
    height: 100%;
    width: 100%;

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__wrapper {
        margin: 0 0 6rem;

        @include media-breakpoint-up(sm) {
            margin: 0 0 2rem;
        }

        @include media-breakpoint-up(xxl) {
            margin: 0 0 6rem;
        }
    }
}