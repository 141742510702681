.offices-plain {
    background-color: map-deep-get($colors, "gray", "default");
    padding: 12px;
    overflow: hidden;
    margin: rem-calc(32) 0;

    @include media-breakpoint-up(md) {
        padding: 24px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        transition: $transition-default;
        display: flex;
        padding: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
        @include media-breakpoint-up(xxl) {
            width: 33%;
        }
    }
}
