.maf__plane {
    background-color: map-deep-get($colors, "red", "default");
    margin-top: 150px;
    min-height: 450px;
    padding: 200px 0 250px;
    position: relative;

    &::before,
    &::after {
        content: "";
        height: 150px;
        left: 0;
        position: absolute;
        right: 0;
    }

    &::before {
        background-image: url("../images/template/maf/ripped_paper_plane_top.png");
        background-position: center bottom;
        top: 0;
    }

    &::after {
        background-image: url("./images/template/maf/ripped_paper_plane_bottom.png");
        background-position: center top;
        bottom: 0;
    }

    &__content {
        color: map-deep-get($colors, "white", "default");
        padding-top: 100px;

        /* stylelint-disable */
        h2 {
            color: map-deep-get($colors, "white", "default");
            font-size: rem-calc(28);
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0.5em;
        }

        h3 {
            color: map-deep-get($colors, "black", "default");
            font-size: rem-calc(14);
            font-weight: 700;
            letter-spacing: 0.2em;
            margin-bottom: 1.15em;
            text-transform: uppercase;
        }

        .rich-text-area {
            line-height: 2.4;
        }
        /* stylelint-enable */
    }

    &__image {
        background-image: url("../images/template/maf/maf_plane.png");
        background-position: top center;
        background-repeat: no-repeat;
        /* stylelint-disable */
        -webkit-background-size: 100%;
        /* stylelint-enable */
        background-size: 100%;
        padding-top: 40%;
        pointer-events: none;

        @include media-breakpoint-up(md) {
            background-position: center right;
            /* stylelint-disable */
            -webkit-background-size: 60%;
            /* stylelint-enable */
            background-size: 60%;
            bottom: 0;
            height: auto;
            left: 0;
            padding-top: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        @include media-breakpoint-up(lg) {
            background-position: 80% 50%;
            /* stylelint-disable */
            -webkit-background-size: 70%;
            /* stylelint-enable */
            background-size: 70%;
        }

        @include media-breakpoint-up(xl) {
            background-position: 80% 30%;
        }
    }
}
