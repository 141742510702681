.aside-sub-services {
    $block: &;
    background-color: map-deep-get($colors, "gray", "default");
    padding: 20px;
    margin-bottom: rem-calc(50);

    &--office {
        margin-top: 4rem;

        #{$block}__list-item-title {
            color: map-deep-get($colors, "red", "default");
        }
    }

    &__title {
        margin-bottom: rem-calc(40);
    }

    &__list-link {
        background-color: map-deep-get($colors, "white", "default");
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem-calc(20);
        color: map-deep-get($colors, "black", "dark");
        box-shadow: 0 2px 14px 0 rgba(map-deep-get($colors, "black", "dark"), .12);
        transition: $transition-default;

        svg {
            width: rem-calc(24);
            height: rem-calc(18);
            margin-left: rem-calc(20);
            fill: map-deep-get($colors, "red", "default");
            transition: $transition-default;
        }

        &--active {
            background-color: map-deep-get($colors, "red", "default");
            color: map-deep-get($colors, "white", "default");
            pointer-events: none;

            svg {
                display: none;
            }

            &:hover {
                background-color: map-deep-get($colors, "red", "default");
                color: map-deep-get($colors, "white", "default");
            }
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "lighter");

            svg {
                transform: translateX(10px);
            }
        }
    }

    &__list-item-title {
        flex: 1;

        /* stylelint-disable */
        span {
            color: map-deep-get($colors, "black", "default");
            font-size: 12px;
            line-height: 14px;
            font-weight: 400;
            text-transform: uppercase;
        }
        /* stylelint-enable */
    }
}
