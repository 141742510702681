﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
    max-width: rem-calc(1920);
}

.body {
    &--overflowHidden {
        overflow: hidden;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
    color: map-deep-get($colors, "red", "default");
}

::selection {
    background-color: map-deep-get($colors, "red", "default");
    color: map-deep-get($colors, "white", "default");
}

[v-cloak] {
    display: none;
}