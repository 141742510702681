.job-count {
    position: absolute;
    top: 50%;
    right: 24px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    border-radius: 12px;
    color: map-deep-get($colors, "red", "default");
    background: map-deep-get($colors, "red", "default");
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
        top: 0;
        right: -10px;
        width: 13px;
        height: 13px;
        font-size: 8px;
        line-height: 13px;
        color: map-deep-get($colors, "white", "default");
        border-radius: 6px;
        background: map-deep-get($colors, "red", "default");
        transform: translateY(0);
    }
}