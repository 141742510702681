.specialism-block {
    min-height: rem-calc(340);
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    flex-wrap: wrap;
    background-color: map-deep-get($colors, "gray", "default");
    padding: rem-calc(16) rem-calc(24);
    margin-bottom: rem-calc(32);
    position: relative;
    color: map-deep-get($colors, "white", "default");
    $specialism-block: &;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(32) rem-calc(48);
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-size: cover;
        background-position: center;
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, transparent 0%, map-deep-get($colors, "black", "default") 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    &__content {
        z-index: 3;
        position: relative;
        width: 100%;
    }

    &__subtitle {
        font-weight: 100;
        margin-bottom: 1em;
        font-size: rem-calc(14);
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
    }

    &__read-more {
        width: 100%;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        opacity: 0;
        height: 0;
        overflow: hidden;
        margin-top: 0;
        transition: $transition-default;

        svg {
            width: rem-calc(30);
            margin-right: rem-calc(8);
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__read-more-content {
        transform: translateX(-10px);
        transition: $transition-default;
        display: flex;
    }

    &:hover,
    &:focus {
        #{$specialism-block}__read-more {
            height: 50px;
            opacity: 1;
            transition: height .3s ease-in-out, opacity .3s ease-in-out .2s;
        }

        #{$specialism-block}__read-more-content {
            transform: translateX(0);
            transition: $transition-default .2s;
        }
    }
}