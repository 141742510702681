// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    line-height: 1.6;

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;

            &::before {
                content: "";
                border-color: transparent transparent transparent map-deep-get($colors, "red", "default");
                border-width: 6px 0 6px 9px;
                border-style: solid;
                position: absolute;
                left: 0;
                top: rem-calc(8);
                width: 0;
                height: 0;
            }
        }
    }

    p {
        margin: 1rem 0;

        &:first-of-type {
            margin-top: 0;
        }

        img {
            max-width: 100%;
        }
    }

    h2, h3, h4 {
        + p {
            margin: 1rem 0;

            &:first-of-type {
                margin-top: 1rem;
            }
        }
    }

    .form:not(.form--custom) {
        background-color: map-deep-get($colors, "gray", "default");
        padding: rem-calc(24);

        @include media-breakpoint-up(xl) {
            padding: rem-calc(40) rem-calc(60);
        }

        &:last-child {
            margin-bottom: rem-calc(50);

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(100);
            }
        }
    }
}

/* stylelint-enable */
