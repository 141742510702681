.office-contact-persons-slider-controls {
    $block: &;
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;

    &__control {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: rem-calc(-25);
        margin-right: rem-calc(-25);
        width: rem-calc(40);
        height: rem-calc(40);

        svg {
            width: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            width: rem-calc(40);
            height: rem-calc(40);
            margin-top: rem-calc(-40);
            //margin-right: rem-calc(-40);
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }

    &--sidebar {
        #{$block}__control {
            margin-top: 0;
        }
    }
}
