.contact-cta {
    position: relative;
    z-index: 5;
    margin-top: rem-calc(32);

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(120);
    }

    &__title {
        margin-bottom: .5em;
    }

    &__form {
        background-color: map-deep-get($colors, "gray", "default");
        padding: rem-calc(24);

        @include media-breakpoint-up(xl) {
            padding: rem-calc(40) rem-calc(60);
        }
    }

    &__sub-text {
        margin-top: 0;
    }

    &__text {
        font-size: rem-calc(20);
        font-style: italic;
    }

    &__content {
        margin-bottom: rem-calc(50);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(100);
        }
    }

    &__employee {
        display: flex;
        align-items: center;
        margin-bottom: 2.5em;
    }

    &__employee-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        padding-right: 5em;
        width: rem-calc(80);
        height: rem-calc(80);
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-color: map-deep-get($colors, "white", "default");
        overflow: hidden;

        svg {
            min-width: rem-calc(68);
            height: rem-calc(68);
            transform: translateX(57%);

            /* stylelint-disable */
            g {
                fill: map-deep-get($colors, "red", "default");
            }
            /* stylelint-enable */
        }
    }
}
