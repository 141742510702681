.card-slider-controls {
    &__control {
        margin-top: rem-calc(-40);
        top: 50%;
        right: 0;
        position: absolute;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }
}
