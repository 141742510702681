.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    //margin: rem-calc(32) 0;

    /* stylelint-disable */
    + .video-wrapper {
        margin-top: 1.5rem;
    }
    /* stylelint-enable */

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
