.presentation {
    &__button {
        margin-bottom: 2rem;
    }

    &__wrapper {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        z-index: 2;

        /* stylelint-disable */
        iframe {
            background-color: map-deep-get($colors, "white", "default");
            border: 1px solid map-deep-get($colors, "gray", "default");
            bottom: 0;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
        /* stylelint-enable */
    }
}
