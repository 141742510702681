.header-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    padding: 80px 4vw 0;
    position: fixed;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 13;
    background-color: map-deep-get($colors, "gray", "default");
    opacity: 0;
    transition: opacity .3s ease-in-out, left 0s linear .3s;

    @include media-breakpoint-up(md) {
        position: absolute;
        flex-wrap: nowrap;
        padding: 0 10vw;
    }

    &__input {
        background-color: map-deep-get($colors, "gray", "default");
        border: 1px solid map-deep-get($colors, "gray", "dark");
        margin-bottom: rem-calc(15);

        @include media-breakpoint-up(md) {
            margin: 0 rem-calc(15) 0 0;
        }
    }

    &__button {
        width: 100%;
        text-align: center;
        display: block;
    }

    &__close {
        display: flex;
        position: absolute;
        right: rem-calc(20);
        top: rem-calc(20);
        line-height: 16px;

        @include media-breakpoint-up(md) {
            position: static;
        }

        // Vendor hamburger
        /* stylelint-disable */
        &.is-active {
            margin-top: 0;
        }
        /* stylelint-enable */
    }

    &__close-text {
        margin-right: 10px;
        text-transform: uppercase;
        color: map-deep-get($colors, "red", "default");
        letter-spacing: .175em;
    }

    &--active {
        opacity: 1;
        left: 0;
        transition: opacity .3s ease-in-out, left 0s linear;
    }
}