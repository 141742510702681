.expandable-text {
    $expandable-text: &;

    &__text {
        position: relative;
        z-index: 1;
        height: rem-calc(300);
        overflow: hidden;
        margin-bottom: rem-calc(20);
        transition: $transition-default;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem-calc(100);
            background: linear-gradient(rgba(255, 255, 255, 0), map-deep-get($colors, "gray", "default"));
        }
    }

    &__read-more {
        display: inline-flex;
        margin-bottom: rem-calc(40);
        color: #000000;

        svg {
            width: 20px;
            height: 34px;
            margin-right: rem-calc(20);
        }

        svg path {
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__apply-now {
        margin-bottom: rem-calc(40);
    }

    &--expanded {
        #{$expandable-text}__text {
            &::after {
                display: none;
            }
        }
    }
}
