.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    transition: $transition-default;
    max-width: rem-calc(1920);
    margin: 0 auto;
    $header: &;

    &__logo-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: rem-calc(25);
        width: rem-calc(125);

        @include media-breakpoint-up(lg) {
            height: rem-calc(50);
            width: rem-calc(225);
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: $transition-default;

        svg {
            height: rem-calc(25);
            width: rem-calc(125);

            @include media-breakpoint-up(lg) {
                height: rem-calc(50);
                width: rem-calc(225);
            }
        }

        &--visible {
            opacity: 1;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
    }

    &__section {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__search-button {
        border-radius: $border-radius;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "white", "default");
        padding: 12px;
        font-weight: 600;
        letter-spacing: 3px;
        font-size: rem-calc(14);
        text-transform: uppercase;
        transition: $transition-default;

        @include media-breakpoint-up(xl) {
            width: rem-calc(210);
        }

        svg {
            width: rem-calc(16);
            height: rem-calc(16);
            transition: $transition-default;

            @include media-breakpoint-up(xl) {
                margin-left: rem-calc(10);
            }
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "default");
            color: map-deep-get($colors, "white", "default");

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &--menuExpanded {
        #{$header} {
            &__list {
                opacity: 1;
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform 0s linear;
            }

            &__listItem, &__subListItem {
                transform: translateY(0);
                opacity: 1;

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i * .05s;
                    }
                }
            }
        }
    }

    &--inverted {
        background-color: map-deep-get($colors, "white", "default");
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");

        #{$header}__search-button {
            border: 1px solid map-deep-get($colors, "gray", "default");
        }

        @include media-breakpoint-up(md) {
            #{$header}__listLink {
                color: map-deep-get($colors, "black", "default");
            }
        }
    }
}
