.contact {
    /* stylelint-disable */
    &-content {
        #text1 {
            margin-bottom: rem-calc(50);

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(100);
            }
        }
    }
    /* stylelint-enable */

    /* stylelint-disable */
    &-phone,
    &-email {
        a {
            font-size: 1.2rem;
        }
    }
    /* stylelint-enable */
}
