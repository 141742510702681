.invoerregeling-toolkit {
    $elm: &;
    font-size: rem-calc(13);
    line-height: rem-calc(18);
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(14);
        line-height: rem-calc(24);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(15);
        line-height: rem-calc(28);
    }

    &--info {
        #{$elm}__info {
            background-color: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "red", "default");

            &::before {
                content: "X";
            }
        }

        #{$elm}__remark {
            display: block;
        }
    }

    &__sectionwrap {
        background-color: map-deep-get($colors, "gray", "default");
        margin-bottom: 20px;
        padding: 15px 20px;

        @include media-breakpoint-up(md) {
            padding: 20px 30px;
        }

        @include media-breakpoint-up(lg) {
            padding: 30px 40px;
        }
    }

    &__section {
        margin-bottom: rem-calc(20);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(40);
        }

        &:not(:first-of-type):not(&--visible) {
            display: none;
        }
    }

    &__title {
        font-size: rem-calc(20);
        margin: 0 0 rem-calc(10);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(24);
        }

        &:not(:first-child) {
            margin-top: rem-calc(20);
        }
    }

    &__subtitle {
        font-style: italic;
    }

    &__headingwrap {
        border-bottom: 1px solid map-deep-get($colors, "gray", "dark");
        margin-bottom: rem-calc(15);
        padding-bottom: rem-calc(2);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(13);
            margin: 0 0 rem-calc(15);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(15);
        }
    }

    &__heading {
        font-weight: 700;

        @include media-breakpoint-up(md) {
            &:first-of-type {
                padding-left: 20px;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-right: rem-calc(10);
            padding-left: rem-calc(20);
        }
    }

    &__footingwrap {
        border-bottom: 1px solid map-deep-get($colors, "gray", "dark");
        border-top: 1px solid map-deep-get($colors, "gray", "dark");
        margin-bottom: rem-calc(15);
        margin-top: rem-calc(15);
        padding-bottom: rem-calc(2);
        padding-top: rem-calc(2);

        @include media-breakpoint-up(md) {
            margin: rem-calc(15) 0;
        }
    }

    &__footing {
        font-weight: 700;

        @include media-breakpoint-up(lg) {
            margin-right: rem-calc(10);
        }

        &:first-of-type {
            font-weight: 600;
            margin-right: 0;

            @include media-breakpoint-up(md) {
                text-align: right;
            }
        }

        &--euro {
            @include media-breakpoint-up(md) {
                padding: 0 rem-calc(11);
            }
        }
    }

    &__label {
        font-weight: 600;
        margin: 0 0 15px;

        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }

    &__group {
        display: none;

        &--visible {
            display: flex;
        }

        &--small {
            @include media-breakpoint-up(md) {
                margin-bottom: rem-calc(5);

                #{$elm}__remark {
                    margin-bottom: rem-calc(19);
                }
            }
        }
    }

    &__radio {
        display: inline-block;
        font-size: rem-calc(13);
        line-height: rem-calc(28);
        margin: 0 rem-calc(15) 0 0;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(14);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(15);
        }
    }

    &__inputwrap {
        background: none;
        border-radius: 2px 0 0 2px;
        line-height: rem-calc(28);
        max-width: 120px;
    }

    &__input {
        border: none;
        border-radius: 0 2px 2px 0;
        font-size: rem-calc(14);
        height: rem-calc(28);
        padding: 0 rem-calc(5);

        &:focus {
            border: none;
        }

        &--readonly {
            background-color: map-deep-get($colors, "gray", "dark");
            cursor: not-allowed;
        }
    }

    &__addition {
        border-radius: 2px 0 0 2px;
        border-right: 1px solid map-deep-get($colors, "gray", "default");
        min-width: 0;
        padding: 0 rem-calc(12);
    }

    &__info {
        background-color: map-deep-get($colors, "red", "default");
        border-radius: 2px;
        color: map-deep-get($colors, "white", "default");
        cursor: pointer;
        font-weight: 700;
        line-height: rem-calc(28);
        padding: 0 5px;
        text-align: center;
        width: 30px;

        &::before {
            content: "?";
        }

        &--empty {
            &::before {
                content: " ";
                display: block;
                width: 30px;
            }
        }
    }

    &__remark {
        background-color: map-deep-get($colors, "white", "default");
        border-radius: 2px;
        display: none;
        font-size: rem-calc(13);
        font-style: italic;
        margin-top: rem-calc(15);
        padding: rem-calc(7) rem-calc(10);
        position: relative;

        @include media-breakpoint-up(md) {
            padding: rem-calc(10) rem-calc(20);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(14);
        }

        &::after {
            border: 8px solid  map-deep-get($colors, "white", "default");
            border-color: transparent transparent map-deep-get($colors, "white", "default");
            content: " ";
            position: absolute;
            right: 7px;
            top: -16px;
            z-index: 1;
        }
    }

    &__message {
        background-color: map-deep-get($colors, "red", "default");
        border-radius: 2px;
        color: map-deep-get($colors, "white", "default");
        display: block;
        font-size: rem-calc(14);
        font-weight: 700;
        margin: rem-calc(15) 0;
        padding: rem-calc(10) rem-calc(20);
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            margin: rem-calc(20) 0 rem-calc(50);
        }
    }
}
