.cases-slider, .cases-wrapper {
    margin-bottom: rem-calc(50);
    margin-top: rem-calc(15);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(100);
        margin-top: rem-calc(30);
    }
}

.cases-bottom{
    margin-bottom: rem-calc(110);
}
