.searchpage-form {
    margin-bottom: 50px;

    &__content-types {
        margin-top: 20px;
    }

    &__header {
        margin-bottom: 0.5em;
    }
}