.office-contact-block {
    $block: &;
    padding: 0 0 0 .625rem;

    &__title {
        color: map-deep-get($colors, "red", "default");
        letter-spacing: 0.2em;
    }

    &__button {
        margin-top: 1rem;
    }

    &__status {
        color: map-deep-get($colors, "red", "default");
        letter-spacing: 0.175em;
    }

    &__link {
        svg {
            display: none;
        }
    }

    &--sidebar {
        #{$block}__wrapper {
            position: relative;
            margin: 0 0 1.4rem;
            padding: 0 0 0 4rem;

            /* stylelint-disable */
            &--p-0 {
                padding: 0;
            }
            /* stylelint-enable */
        }

        #{$block}__title {
            color: map-deep-get($colors, "black", "default");
            letter-spacing: 0;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0 0 .3rem;
        }

        #{$block}__address {
            font-size: 24px;
            line-height: 24px;
            font-weight: $font-weight-bold;
            margin: 0 0 2.75rem 4rem;
        }

        #{$block}__address-icon {
            background-color: map-deep-get($colors, "red", "default");
            border-radius: 50%;
            height: 50px;
            width: 50px;
            position: absolute;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        #{$block}__zipcode {
            margin-left: 4rem;
        }

        #{$block}__phone-icon,
        #{$block}__email-icon {
            background-color: map-deep-get($colors, "gray", "default");
            border-radius: 50%;
            height: 50px;
            width: 50px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        #{$block}__link {
            display: flex;
            align-items: center;
            color: map-deep-get($colors, "black", "default");

            svg {
                margin-left: .5rem;
                display: block;
                transition: $transition-default;
            }

            &:hover {
                /* stylelint-disable */
                svg {
                    transform: translateX(10px);
                }
                /* stylelint-enable */
            }
        }

        #{$block}__hours {
            margin: 0;
        }

        #{$block}__caption {
            margin: 3.25rem 0 4.125rem;
        }

        /* stylelint-disable */
        .office-contact-item {
            margin: 0;
        }
        /* stylelint-enable */
    }
}

.office-contact-blocks {
    margin: 1rem 0 5rem;
}
