.filter {
    margin-bottom: rem-calc(36);

    &__content {
        position: relative;
        z-index: 2;
        background-color: map-deep-get($colors, "gray", "default");
        padding: 20px 10px;

        @include media-breakpoint-up(xl) {
            padding: 20px 30px;
        }
    }

    &__select{
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__button {
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(xl) {
            width: auto;
        }
    }
}