.banner-cta {
    margin: rem-calc(100) 0 rem-calc(150);

    &__image-wrapper {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(map-deep-get($colors, "black", "default"), .5);
        }
    }

    &__img {
        width: 100%;
    }

    &__title {
        position: absolute;
        z-index: 1;
        color: map-deep-get($colors, "white", "default");
        font-size: 6vw;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        margin: 0 auto;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
            top: rem-calc(60);
            bottom: inherit;
        }
        @include media-breakpoint-up(xl) {
            right: 0;
            left: inherit;
            bottom: rem-calc(60);
            width: 58%;
        }
        @include media-breakpoint-up(xl) {
            font-size: rem-calc(90);
        }
    }

    &__text {
        margin-top: rem-calc(32);
    }

    &__video {
        margin-top: rem-calc(48);

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(-48);
        }
        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(-150);
        }
    }
}