.spin {
    transform-box: fill-box;
    transform-origin: 75% -100%;
    animation: spin 20s linear infinite;

    &--test {
        animation-name: spin-test;
    }

    &--center {
        transform-origin: 50%;
    }

    &--reverse {
        animation-direction: reverse;
    }

    &--quick {
        animation-duration: 15s;
    }

    &--slow {
        animation-duration: 25s;
    }
}

@keyframes spin-test {
    0% {
        transform: rotate3d(0, 0, 0, 0deg);
    }

    100% {
        transform: rotate3d(2, .75, 1, 360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate3d(0, 0, 0, 0deg);
    }

    100% {
        transform: rotate3d(0, 0, 1, 360deg);
    }
}
