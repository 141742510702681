.checkbox-wrapper {
    &__input-wrapper {
        &::before {
            border: 2px solid #ccccccb5;
        }
    }
}

.radio-wrapper {
    &__input-wrapper {
        &::before {
            border: 2px solid #ccccccb5;
        }
    }
}

/* stylelint-disable */
.form--async {
    .form__group {
        .form__input, .form__input--textarea {
            border: 2px solid #ccccccb5;
            border-radius: rem-calc(5);
        }
    }
}
/* stylelint-enable */
