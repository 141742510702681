.sitemap {
    margin-left: 1em;
    list-style-type: circle;

    &__element {
        margin-bottom: .5em;
    }

    &__child {
        list-style-type: square;
        margin-top: .5em;
        margin-left: 1em;
    }
}