.contact-person {
    position: relative;
    z-index: 1;

    &__title {
        margin-bottom: rem-calc(16);

        &--red {
            color: map-deep-get($colors, "red", "default");
        }
    }

    &__list {
        margin-bottom: rem-calc(24);
    }

    &__list-item {
        position: relative;
        padding-left: 50px;
        margin-bottom: rem-calc(16);
    }

    &__icon {
        width: rem-calc(20);
        height: rem-calc(20);
        position: absolute;
        left: rem-calc(-50);
    }

    &__list-link {
        color: map-deep-get($colors, "black", "default");
        position: relative;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__image {
        max-width: rem-calc(270);
        width: 100%;
    }
}