.video-js {
    width: auto;
    height: auto;

    .vjs-big-play-button {
        display: none;
    }

    .vjs-control-bar {
        z-index: 5;
    }
}