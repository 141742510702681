.calculator {
    font-size: rem-calc(14);

    &__section {
        background-color: map-deep-get($colors, "gray", "default");
        padding: 20px 40px 40px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding: 20px 60px 60px;
        }
    }

    &__caption {
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: 0.2em;
        line-height: 1.14;
        margin-bottom: 1.5em;
    }

    &__title {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }

    &__result-row {
        background-color: map-deep-get($colors, "white", "default");
        margin-bottom: 15px;
        padding: 10px 20px;
        font-weight: 100;
    }

    &__disclaimer {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

.calculator-result {
    &__heading {
        color: map-deep-get($colors, "red", "default");
    }

    &__border {
        display: none;
        position: absolute;
        top: -40px;
        bottom: -40px;
        left: -10px;
        background: map-deep-get($colors, "gray", "darker");
        width: 1px;

        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }

    &__label {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__value {
        letter-spacing: 0.2em;
        font-weight: 100;
    }
}
