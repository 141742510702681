.offices {
    background-color: map-deep-get($colors, "gray", "default");
    padding: 30px;
    position: relative;
    margin-bottom: 100px;

    @include media-breakpoint-up(xl) {
        padding: 100px 60px;
    }
    @include media-breakpoint-up(xl) {
        padding: 100px 120px 200px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: rem-calc(40) rem-calc(-10) 0;
        position: relative;
        z-index: 1;
        justify-content: center;
        pointer-events: none;

        @include media-breakpoint-up(xl) {
            width: 65%;
            justify-content: left;
            margin: 0 rem-calc(-10);
        }

        @include media-breakpoint-up(xxl) {
            width: 61%;
        }
    }

    &__list-item {
        display: block;
        width: 100%;
        padding: 0 10px 20px;
        pointer-events: auto;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 33.333%;
        }
    }

    &__map {
        position: relative;
        margin: 0 auto;
        display: inline-flex;

        @include media-breakpoint-up(xl) {
            top: rem-calc(110);
            right: 0;
            position: absolute;
        }

        @include media-breakpoint-up(xxl) {
            right: rem-calc(-50);
        }
    }

    &__inner-map-img {
        width: 80vw;
        max-width: rem-calc(670);

        @include media-breakpoint-up(xl) {
            width: 45vw;
            margin-right: 3vw;
        }
        @include media-breakpoint-up(xl) {
            width: 50vw;
            margin-right: 3vw;
        }
        @include media-breakpoint-up(xxl) {
            width: 35vw;
            margin-right: 2vw;
        }
    }

    &__markers {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__marker {
        position: absolute;
        cursor: pointer;
        transform: translateX(-50%);
        opacity: 0;
        top: 0;
        transition: opacity $transition-default-time $transition-default-smoothing, top $transition-default-time $transition-cubic-smoothing;

        svg {
            width: 15px;
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                width: 20px;
            }
        }

        &:hover,
        &:focus,
        &--active {
            svg {
                fill: map-deep-get($colors, "red", "default");
            }
        }
    }
}