.page {
    /* stylelint-disable */
    // Custom code, cant copy paste this as easily (just dont..)
    &--positionering {
        .hero {
            &__pattern {
                z-index: 4;
            }
        }

        .top-section {
            padding-bottom: 5.25rem;

            &::after {
                background-color: map-deep-get($colors, "gray", "default");


                @include media-breakpoint-up(xl) {
                    bottom: rem-calc(-180);
                }
            }

            #text1 {
                h1 {
                    color: map-deep-get($colors, "white", "default");
                    font-size: 10vw;
                    font-weight: 700;
                    margin-top: 0;

                    @include media-breakpoint-up(md) {
                        font-size: rem-calc(56);
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: rem-calc(64);
                    }
                }

                p {
                    color: map-deep-get($colors, "white", "default");
                }
            }

            .dual-video {
                margin-bottom: rem-calc(80);

                &--pull-top {
                    margin-top: -27rem;
                }

                &__video {
                    margin-bottom: 0;
                }

            }
        }

        .section-video {
            margin: rem-calc(100) 0;

            .video {
                &__wrapper {
                    &::before {
                        background: radial-gradient(43.14% 43.14% at 50% 50%, #D2003A 0%, rgba(210, 0, 58, 0.00) 100%);
                    }
                }
            }
        }

        .zigzag-section {
            margin: rem-calc(100) 0;

            &__row {
                gap: 2rem 0;

                @include media-breakpoint-up(md) {
                    gap: 5rem 0;
                }

                @include media-breakpoint-up(lg) {
                    gap: rem-calc(100) 0;
                }
            }
        }

        .zigzag-content {
            &__image-wrapper {
                min-height: 400px;

                @include media-breakpoint-up(lg) {
                    min-height: 400px;
                }
            }
        }
    }
    /* stylelint-enable */
}
