.text {
    &__image {
        position: relative;
        z-index: 2;
    }

    &__text {
        position: relative;
        z-index: 2;
        padding-bottom: 24rem;
    }
}
