.card-slider {
    margin-bottom: rem-calc(100);
    margin-top: rem-calc(20);
    position: relative;
    overflow: hidden;

    &__slide {
        transition: opacity .3s ease-in-out;
        width: 100%;
        opacity: 0;
        padding-right: 40px;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            width: 50%;
            opacity: .5;
        }

        @include media-breakpoint-up(lg) {
            width: 31%;
        }

        @include media-breakpoint-up(xl) {
            padding: 0;
            margin-right: 1%;
        }

        &--story {
            opacity: 1;
        }

        /* stylelint-disable */
        &.is-selected {
            opacity: 1;
        }
        /* stylelint-enable */
    }
}