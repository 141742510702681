.header-top {
    height: rem-calc(38);
    background-color: map-deep-get($colors, "black", "dark");
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: rem-calc(14);
    font-weight: 600;
    $header-top: &;

    &__list {
        height: 100%;
        display: flex;
    }

    &__list-item {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        transition: $transition-default;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "default");

            #{$header-top}__list-link {
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__list-link {
        padding: 10px;
        display: inline-flex;
        align-items: center;
        transition: $transition-default;

        svg {
            fill: map-deep-get($colors, "white", "default");
            margin-right: rem-calc(5);
        }

        &:hover,
        &:focus {
            & + #{$header-top}__sub-list {
                opacity: 1;
                right: 0;
                left: 0;
                transition: margin 0s linear, opacity .2s ease-in-out;

                @include media-breakpoint-up(md) {
                    left: inherit;
                }
            }
        }
    }

    &__sub-list {
        position: fixed;
        z-index: 12;
        min-width: rem-calc(200);
        top: rem-calc(38);
        right: rem-calc(-9999);
        left: rem-calc(9999);
        padding: 10px;
        background-color: map-deep-get($colors, "red", "default");
        opacity: 0;
        transition: right 0s linear .2s, left 0s linear .2s, opacity .2s ease-in-out;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: inherit;
        }

        &:hover,
        &:focus {
            opacity: 1;
            right: 0;
            left: 0;
            transition: right 0s linear, left 0s linear, opacity .2s ease-in-out;

            @include media-breakpoint-up(md) {
                left: inherit;
            }
        }
    }

    &__sub-list-link {
        color: map-deep-get($colors, "white", "default");
        display: block;
        padding: 5px 10px;
        transition: $transition-default;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }

    &__language-dropdown {
        height: 100%;
        display: flex;
        position: relative;
    }

    &__language-dropdown-current {
        height: 100%;
        padding: 0 10px;
        background-color: map-deep-get($colors, "gray", "default");
        display: flex;
        align-items: center;
        cursor: pointer;
        color: map-deep-get($colors, "red", "default");

        &:hover,
        &:focus {
            & + #{$header-top}__language-list {
                left: 0;
                opacity: 1;
                transition: opacity .3s ease-in-out, left 0s linear;
            }
        }
    }

    &__language-list {
        list-style-type: none;
        background-color: map-deep-get($colors, "gray", "default");
        position: absolute;
        right: 0;
        left: 100%;
        z-index: 12;
        top: rem-calc(38);
        opacity: 0;
        transition: opacity .3s ease-in-out, left 0s linear .3s;

        @include media-breakpoint-up(md) {
            height: 100%;
            display: flex;
            margin-left: rem-calc(10);
            padding: 0 10px;
            position: static;
            opacity: 1;
        }

        &:hover,
        &:focus {
            left: 0;
            opacity: 1;
            transition: opacity .3s ease-in-out, left 0s linear;
        }
    }

    @include media-breakpoint-up(md) {
        &__language-list-item {
            height: 100%;
        }
    }

    &__language-list-link {
        display: inline-flex;
        width: 100%;
        padding: 10px;
        align-items: center;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            height: 100%;
            padding: 0 10px;
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "gray", "dark");
        }

        svg {
            width: rem-calc(14);
            height: rem-calc(14);
        }
    }
}
