.office-item {
    background-color: map-deep-get($colors, "white", "default");
    transition: $transition-default;
    display: block;
    color: map-deep-get($colors, "black", "default");
    padding: 16px 24px;
    $office-item: &;

    &__more {
        margin-top: rem-calc(10);
        display: flex;
        align-items: center;
        justify-content: left;

        svg {
            width: 32px;
            height: 1em;
            margin-right: 8px;
            fill: map-deep-get($colors, "red", "default");
            transition: $transition-default;
        }
    }

    &:hover,
    &:focus,
    &--active {
        background-color: map-deep-get($colors, "red", "default");
        color: map-deep-get($colors, "white", "default");

        #{$office-item}__more {
            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}