.special-block {
    height: 100%;
    padding-bottom: rem-calc(20);
    display: flex;
    $specials-block: &;

    &__inner {
        min-height: rem-calc(220);
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        flex-wrap: wrap;
        background-color: map-deep-get($colors, "gray", "default");
        padding: rem-calc(25) rem-calc(30);
        position: relative;
        color: map-deep-get($colors, "white", "default");
        height: 100%;
        width: 100%;

        &::before {
            content: "";
            background: linear-gradient(180deg, transparent 0%, map-deep-get($colors, "black", "default") 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
        }
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-size: cover;
        background-position: center;
    }

    &__content {
        z-index: 3;
        position: relative;
        width: 100%;
    }

    &__title{
        margin-bottom: 0.8em;
    }

    &__read-more {
        width: 100%;
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-top: 0;
        transition: $transition-default;

        svg {
            width: rem-calc(30);
            margin-right: rem-calc(8);
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__read-more-content {
        display: flex;
        align-items: center;
    }

    &__read-more-text {
        transition: $transition-default;
    }

    &:hover,
    &:focus {
        #{$specials-block} {
            &__read-more {
                transform: translateX(10px);
            }

            &__read-more-text {
                margin-left: 10px;
            }
        }
    }
}

.special__content {
    margin-top: rem-calc(100);
}
