.office-maps {
    height: 100%;
    width: 100%;
    padding-bottom: 1.25rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__wrapper {
        margin: 0 0 6rem;

        @include media-breakpoint-up(sm) {
            margin: 0 0 2rem;
        }

        @include media-breakpoint-up(xxl) {
            margin: 0 0 6rem;
        }
    }
}

/* stylelint-disable */
    .st0{
        fill:#EECDD7;stroke:#F0F0F0;
    }
/* stylelint-enable*/