.quote {
    position: relative;
    margin: rem-calc(32) 0;
    padding-left: 20px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-left: 1px solid map-deep-get($colors, "black", "default");
    }

    &__quote {
        margin-bottom: rem-calc(16);
    }

    &__cite {
        position: relative;
        margin-top: rem-calc(40);
    }

    &__image {
        width: rem-calc(50);
        height: rem-calc(50);
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        top: rem-calc(-16);
    }

    &__name {
        color: map-deep-get($colors, "red", "default");
        padding-left: 70px;
        margin-top: rem-calc(25);
        display: block;
    }
}
