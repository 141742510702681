.testimonial-slide {
    padding: 20px 30px 30px;
    width: 100%;
    min-height: 100%;
    font-weight: 600;
    color: map-deep-get($colors, "white", "default");
    background-color: map-deep-get($colors, "red", "default");
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: 40px 60px 60px;
    }

    @include media-breakpoint-up(lg) {
        padding: 60px 90px 90px;
    }

    @include media-breakpoint-up(xl) {
        padding: 80px 120px 120px;
    }

    &__caption {
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 0.2em;
        margin-bottom: 3em;
        display: inline-block;
    }

    &__content {
        line-height: 1.14em;
        font-size: rem-calc(18);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(24);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(28);
        }
    }
}
