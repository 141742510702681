.follow-us {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2.5rem;

    &__content {
        display: flex;
        flex-basis: auto;
        align-items: center;
        flex-flow: row wrap;
        padding-bottom: 1.5rem;
        border-bottom: solid 1px map-deep-get($colors, "gray", "default");
    }

    &__label {
        @extend .h4;
        margin-right: 1.25rem;
        margin-bottom: 0;
    }

    &__wrapper {
        margin: 1rem 0;
        display: flex;
        align-items: center;
        flex: 0 1;
    }

    &__arrow {
        margin-right: .75rem;

        svg {
            fill: map-deep-get($colors, "red", "default");
            width: 2rem;
        }
    }

    &__item {
        margin-right: 1rem;

        svg {
            fill: map-deep-get($colors, "red", "default");
            width: 1.375rem;
            height: 1.375rem;
        }
    }
}
