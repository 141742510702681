﻿/// <reference path="../_reference.scss" />

//
// Breakpoints
// Make sure these are the same as foundation"s breakpoints!
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 640px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1440px
);

$breakpoints: ("small": 0, "medium": 640px, "large": 1024px, "xlarge": 1200px, "xxlarge": 1440px);

//
// Colors
$colors: (
    "white": ("default": #ffffff),
    "black": ("dark": #000000, "default": #1b1b1b),
    "gray": ("darker": #6d6d6d, "dark": #cccccc, "default": #f0f0f0, "light": #fafafa),
    "red": ("dark": darken(#e50044, 40%), "default": #e50044, "light": lighten(#e50044, 25%), "lighter": lighten(#e50044, 50%)),
    "validation": ("valid": #55b890, "invalid": #e50044, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e50044, 25%)),
    "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
    "graph": ("pink": #f8c0d1, "blue": #b5effe, "green": #bbf6cd, "purple": #c1bcdd, "orange": #ffdd97)
);

//
// Colors
$spacing: (
    "default": (1: 8px, 2: 16px, 3: 24px, 4: 32px, 5: 40px, 6: 48px, 7: 56px, 8: 64px, 9: 72px, 10: 80px),
    "small": (1: 4px, 2: 8px, 3: 12px, 4: 16px, 5: 20px, 6: 24px, 7: 28px, 8: 32px, 9: 36px, 10: 40px)
);

//
// Fonts
@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-Italic.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-Semibold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-SemiboldItalic.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-BoldItalic.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Calluna Sans;
    src: url("/fonts/CallunaSans-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$font-default: "Calluna Sans", arial;

// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

$animation-default-duration: 1s;
$animation-default-fill-mode: forwards;
$animation-default-delay: .3s;

//
// Border Radius
$border-radius: 2px;

$header-height: ("small": 50px, "medium": 80px);
