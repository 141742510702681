.button {
    border-radius: $border-radius;
    padding: rem-calc(8) rem-calc(32);
    min-height: rem-calc(40);
    background-color: map-deep-get($colors, "red", "default");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    font-size: rem-calc(14);
    transition: $transition-default;
    font-weight: 400;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "red", "light");
    }

    &--invert {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "red", "default");

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--ghost {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "red", "default");
        border: 1px solid map-deep-get($colors, "red", "default");

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
            color: map-deep-get($colors, "white", "default");
        }
    }

    &--wide {
        width: 100%;
    }

    &--bigwhite {
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0;
        text-transform: initial;
        padding: 1.563rem 1.25rem;

        &:hover {
            svg {
                /* stylelint-disable */
                * {
                    transition: $transition-default;
                    fill: map-deep-get($colors, "white", "default");
                }
                /* stylelint-enable */
            }
        }
    }

    &--bigred {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        font-size: 20px;
        line-height: 23px;
        font-weight: 700;
        color: map-deep-get($colors, "white", "default");
        background-color: map-deep-get($colors, "red", "default");
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
        position: relative;
        transition: $transition-default;
        margin-bottom: 21px;

        &::after {
            content: "";
            display: inline-block;
            width: 34px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='21' viewBox='0 0 34 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 -0.710059 0.704142 0.710059 23.4761 1.29933)' fill='white'/%3E%3Crect y='11.1122' width='0.816326' height='32.7857' transform='rotate(-90 0 11.1122)' fill='white'/%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 0.710059 0.704142 -0.710059 23.4761 19.9228)' fill='white'/%3E%3C/svg%3E%0A");
            transition: $transition-default;
        }

        &:hover {
            background-color: map-deep-get($colors, "red", "light");

            &::after {
                transform: translateX(10px);
            }
        }
    }

    &--bigwhite-arrow-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        font-size: 20px;
        line-height: 23px;
        font-weight: 700;
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "red", "default");
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
        position: relative;
        transition: $transition-default;
        margin-bottom: 21px;

        &::after {
            content: "";
            display: inline-block;
            width: 34px;
            height: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='21' viewBox='0 0 34 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 -0.710059 0.704142 0.710059 23.4761 1.29932)' fill='%23E50044'/%3E%3Crect y='11.1122' width='0.816326' height='32.7857' transform='rotate(-90 0 11.1122)' fill='%23E50044'/%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 0.710059 0.704142 -0.710059 23.4761 19.9228)' fill='%23E50044'/%3E%3C/svg%3E%0A");
            transition: $transition-default;
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
            background-color: map-deep-get($colors, "red", "light");

            &::after {
                transform: translateX(10px);
                background-image: url("data:image/svg+xml,%3Csvg width='34' height='21' viewBox='0 0 34 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 -0.710059 0.704142 0.710059 23.4761 1.29933)' fill='white'/%3E%3Crect y='11.1122' width='0.816326' height='32.7857' transform='rotate(-90 0 11.1122)' fill='white'/%3E%3Crect width='0.812932' height='13.8199' transform='matrix(0.704142 0.710059 0.704142 -0.710059 23.4761 19.9228)' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

.round-button {
    background-color: map-deep-get($colors, "red", "default");
    width: rem-calc(50);
    height: rem-calc(50);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-default;

    @include media-breakpoint-up(xl) {
        width: rem-calc(80);
        height: rem-calc(80);
    }

    svg {
        width: rem-calc(26);
        height: auto;
        fill: map-deep-get($colors, "white", "default");
    }
}
