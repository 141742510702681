.agenda-item {
    background-color: map-deep-get($colors, "white", "default");
    box-shadow: 0 2px 14px 0 rgba(map-deep-get($colors, "black", "default"), 0.12);
    display: flex;
    padding: 10px 40px 10px 0;
    transition: $transition-default;
    width: 100%;

    @include media-breakpoint-up(xl) {
        &:hover {
            background-color: rgba(map-deep-get($colors, "red", "default"), 0.2);
        }
    }

    &__date {
        color: map-deep-get($colors, "red", "default");
        font-size: rem-calc(14);
        font-weight: 600;
        margin-right: 15px;
        margin-left: 15px;
        text-align: center;
    }

    &__month {
        display: flex;
        flex: 1 1 100%;
    }

    &__content {
        color: map-deep-get($colors, "black", "default");
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(20);
        font-weight: 600;
        margin-bottom: 0.5em;
        white-space: normal;
    }

    &__link {
        margin-top: 10px;

        svg {
            fill: map-deep-get($colors, "red", "default");
            width: rem-calc(35);
            height: 1em;
        }
    }
}