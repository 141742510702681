.top-section {
    background-color: map-deep-get($colors, "gray", "default");
    position: relative;
    margin-bottom: 25px;
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding-bottom: rem-calc(250);
    }

    &::after {
        content: "";
        background-color: map-deep-get($colors, "white", "default");
        height: rem-calc(250);
        width: 120%;
        position: absolute;
        bottom: rem-calc(-90);
        left: -10%;
        z-index: 0;
        transform: rotate(-10deg);

        @include media-breakpoint-up(xl) {
            height: rem-calc(350);
        }

        @include media-breakpoint-up(xl) {
            bottom: rem-calc(-180);
        }
    }

    &--pull-top {
        padding-top: rem-calc(200);
        margin-top: rem-calc(-200);
        overflow: hidden;
    }

    &--white {
        background-color: map-deep-get($colors, "white", "default");

        &::after {
            background-color: map-deep-get($colors, "gray", "default");
        }
    }

    &--home {
        @include media-breakpoint-between(md, lg) {
            margin-top: -380px;
        }
    }

    &--landing {
        padding-bottom: 3rem;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
            padding-bottom: 6rem;
        }

        &::after {
            display: none;
        }

        #{$elm}__image {
            text-align: center;

            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &--intro {
            @include media-breakpoint-up(xl) {
                font-size: 1.5rem;
                line-height: 1.3;
            }
        }

        /* stylelint-disable */
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        /* stylelint-enable */
    }
}
