.blog {
    &__image {
        background-position: center;
        background-size: cover;
        background-color: lighten(map-deep-get($colors, "red", "default"), 25%);
        margin-bottom: 2rem;
        padding-top: 33.3%;
        position: relative;
        z-index: 2;
    }

    &__text {
        padding-bottom: 24rem;
        position: relative;
        z-index: 2;
    }
}