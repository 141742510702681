.employee {
    $block: &;
    background-color: map-deep-get($colors, "gray", "default");
    padding: 2.5rem 1.25rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
        width: auto;
    }

    @include media-breakpoint-up(xl) {
        padding: 2.5rem 3.75rem;
    }

    &--sidebar {
        margin-top: 1rem;
        padding: 1rem;

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        #{$block}__image {
            width: 6rem;
            height: 6rem;
            padding: 0;
            margin: 0;

            @include media-breakpoint-up(xl) {
                width: 8.75rem;
                height: 8.75rem;
            }
        }

        #{$block}__title {
            font-size: 1.1rem;

            @include media-breakpoint-up(xl) {
                font-size: 1.3rem;
            }
        }

        #{$block}__caption, #{$block}__function, #{$block}__phone, #{$block}__email, #{$block}__linkedin {
            font-size: 0.813rem;

            @include media-breakpoint-up(md) {
                font-size: 0.813rem;
            }

            svg {
                @include media-breakpoint-down(lg) {
                    height: 13px;
                    margin-right: .5rem;
                }
            }
        }

        #{$block}__content {
            margin-left: .25rem;

            @include media-breakpoint-up(md) {
                margin-left: .5rem;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 1.25rem;
            }
        }
    }

    &--card {
        margin-bottom: 2rem;

        #{$block}__link {
            display: flex;
            color: map-deep-get($colors, "black", "default");

            &:hover {
                /* stylelint-disable */
                #{$block}__hover {
                    opacity: 1;
                    pointer-events: auto;
                }

                #{$block}__caption, #{$block}__title, #{$block}__function {
                    opacity: 0;
                    pointer-events: none;
                }
                /* stylelint-enable */
            }
        }

        #{$block}__hover {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            font-size: 9px;
            line-height: 9px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: map-deep-get($colors, "red", "default");
            display: flex;
            align-items: center;
            transition: $transition-default;

            svg {
                margin-left: .5rem;
                display: block;
            }
        }

        #{$block}__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        #{$block}__image {
            width: 7.813rem;
            height: 7.813rem;
            padding: 0;
            margin: 0;
        }

        #{$block}__caption {
            font-size: 9px;
            line-height: 1;
            letter-spacing: 2px;
            transition: $transition-default;
        }

        #{$block}__title {
            font-weight: 600;
            font-size: 17px;
            line-height: 19px;
            transition: $transition-default;
        }

        #{$block}__function {
            font-size: 11px;
            line-height: 14px;
            transition: $transition-default;
        }
    }

    &__image {
        width: 80%;
        margin: 10%;
        padding-top: 80%;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
            width: rem-calc(220);
            height: rem-calc(220);
            padding-top: 0;
            margin: 0 0 20px;
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    &__caption {
        color: map-deep-get($colors, "red", "default");
    }

    &__content {
        margin-left: rem-calc(20);
    }

    &__function {
        display: block;
        margin-top: rem-calc(5);
    }

    &__list {
        margin-top: rem-calc(20);
    }

    &__list-item {
        margin-top: rem-calc(6);
    }

    &__phone,
    &__email,
    &__linkedin {
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        align-items: center;

        svg {
            max-width: rem-calc(18);
            max-height: rem-calc(20);
            margin-right: rem-calc(15);
        }

        &:hover,
        &:focus {
            text-decoration: underline;
            color: map-deep-get($colors, "red", "default");
        }
    }

    &__button {
        margin-top: rem-calc(30);
    }
}

.col-mb {
    margin-bottom: 4rem;
}
