.team-slider {
    margin-bottom: rem-calc(100);

    &__slide {
        width: 100%;
        padding-right: 40px;
        opacity: .5;
        transition: opacity .3s ease-in-out;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }
        @include media-breakpoint-up(xl) {
            padding: 0;
        }

        /* stylelint-disable */
        &.is-selected {
            opacity: 1;
        }
        /* stylelint-enable */
    }

    &__image {
        margin-right: rem-calc(32);
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 40%;
            max-width: rem-calc(460);
        }
    }

    &__title {
        max-width: rem-calc(500);
        margin-top: rem-calc(48);

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        &::before {
            content: "“";
        }

        &::after {
            content: "”";
        }
    }

    &__name {
        color: map-deep-get($colors, "red", "default");
        margin-top: rem-calc(32);
        display: block;
    }
}