.maf__banner {
    background-color: map-deep-get($colors, "red", "default");
    margin-top: rem-calc(10);
    padding: 8px 36px;
    position: relative;

    @include media-breakpoint-down(md) {
        margin-bottom: rem-calc(100);
    }

    &--title {
        color: map-deep-get($colors, "white", "default");
        font-style: italic;
        font-weight: 700;
    }

    &--button {
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        align-items: center;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "white", "default");
            width: rem-calc(35);
            margin-right: 0.5em;
            height: 1em;
        }

        &:hover {
            svg {
                margin-right: 0.2em;
            }
        }
    }

    &--image {
        background-image: url("../images/template/maf/maf_plane_sm.png");
        background-position: right center;
        background-repeat: no-repeat;
        /* stylelint-disable */
        -webkit-background-size: 35%;
        /* stylelint-enable */
        background-size: 35%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
    }
}
