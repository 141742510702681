.section {
    &__title {
        display: inline-block;
        margin-bottom: 0;
    }

    &__caption {
        margin-top: 1rem;
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        align-items: center;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "red", "default");
            width: rem-calc(35);
            margin-right: 0.5em;
            height: 1em;
        }

        &:hover {
            svg {
                margin-right: 0.2em;
            }
        }
    }
}
