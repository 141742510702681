.agenda {
    background-color: map-deep-get($colors, "white", "default");
    padding: 10px;
    padding-bottom: 0;

    &--grey {
        background-color: map-deep-get($colors, "gray", "default");
    }

    @include media-breakpoint-up(md) {
        padding: 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 20px;
    }

    @include media-breakpoint-up(xl) {
        padding: 25px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 30px;
    }

    &--large {
        @include media-breakpoint-up(lg) {
            padding: 30px;
        }

        @include media-breakpoint-up(xl) {
            padding: 40px;
        }

        @include media-breakpoint-up(xxl) {
            padding: 50px;
        }
    }

    &__title {
        display: inline-block;
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 0.2em;
        color: map-deep-get($colors, "red", "default");
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        align-items: center;

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "red", "default");
            width: rem-calc(35);
            margin-right: 0.5em;
            height: 1em;
        }

        &:hover {
            svg {
                margin-right: 0.2em;
            }
        }
    }

    &__list{
        margin-top: 30px;
    }

    &__list-item {
        display: flex;
        height: 100%;
        padding-bottom: 10px;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding-bottom: 15px;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 20px;
        }
        @include media-breakpoint-up(xl) {
            padding-bottom: 25px;
        }
        @include media-breakpoint-up(xxl) {
            padding-bottom: 30px;
        }
    }
}