.about-video {
    position: relative;
    z-index: 5;
    margin-bottom: rem-calc(32);

    &--pull-top {
        margin-top: rem-calc(-100);

        @include media-breakpoint-up(xl) {
            margin-top: -36vh;
        }
    }

    &__video {
        margin-bottom: rem-calc(64);

        &--dom {
            margin-top: 14vh;
        }
    }
}

@media (min-width: 1200px) {
    /* stylelint-disable */
    main.about {
        .about-video {
            display: block;

            &,
            &__video {
                bottom: 0;
                left: 0;
                margin: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
            }
        }

        .top-section {
            .about-video {
                display: none;
            }
        }
    }
    /* stylelint-enable */
}
