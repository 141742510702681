.search-result {
    &__content-type {
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__header {
        margin-bottom: 0.5em;
    }

    &__list-item {
        line-height: 1.25em;
        margin-bottom: 0.5em;
    }
}