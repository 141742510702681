.case-block {
    width: 95%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: relative;
    padding-right: 5%;
    $elm: &;

    &__link {
        display: block;
    }

    &--overview {
        opacity: 1;
        width: 100%;
        padding-right: 0;
        margin-bottom: 3rem;
    }

    &::before {
        @include media-breakpoint-up(md) {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(270deg, transparent 0%, map-deep-get($colors, "black", "dark") 100%);
        }
    }

    /* stylelint-disable */
    &.is-selected {
        opacity: 1;
        pointer-events: initial;

        + #{$elm} {
            opacity: 0.5;
        }
    }

    /* stylelint-enable */

    &__image {
        width: 100%;
    }

    &__content {
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 25px 50px;
        }

        @include media-breakpoint-up(xl) {
            padding: 30px 60px;
        }

        @include media-breakpoint-up(xl) {
            padding: 40px 80px;
        }

        @include media-breakpoint-up(xxl) {
            padding: 45px 90px;
        }
    }

    &__caption {
        text-transform: uppercase;
        color: map-deep-get($colors, "red", "default");
        font-size: rem-calc(16);
        font-weight: 400;
        letter-spacing: 0.2em;
        margin-bottom: 0.15em;
    }

    &__title {
        font-size: rem-calc(20);
        margin-bottom: 1.5em;
        width: 100%;
        color: map-deep-get($colors, "black", "default");

        @include media-breakpoint-up(md) {
            color: map-deep-get($colors, "white", "default");
            font-size: rem-calc(32);
            margin-bottom: 0.5em;
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            width: 35%;
        }
    }
}
