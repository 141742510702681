.sub-service-card {
    background-color: map-deep-get($colors, "gray", "default");
    transition: $transition-default;
    display: block;
    padding: 30px;
    min-height: rem-calc(150);
    margin-bottom: rem-calc(20);
    position: relative;
    $sub-service-card: &;

    &__caption {
        transition: $transition-default;
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        transition: $transition-default;
    }

    &__read-more {
        display: inline-flex;
        align-items: center;
        position: absolute;
        bottom: rem-calc(15);
        left: rem-calc(30);

        svg {
            transition: $transition-default;
            width: rem-calc(30);
            height: rem-calc(18);
            fill: map-deep-get($colors, "red", "default");
            margin-right: rem-calc(20);
        }
    }

    &__read-more-text {
        transition: $transition-default;
        opacity: 0;
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "red", "default");

        #{$sub-service-card}__title {
            color: map-deep-get($colors, "white", "default");
            margin-top: rem-calc(-20);
        }

        #{$sub-service-card}__caption {
            opacity: 0;
        }

        #{$sub-service-card}__read-more {
            color: map-deep-get($colors, "white", "default");

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        #{$sub-service-card}__read-more-text {
            opacity: 1;
        }
    }
}
