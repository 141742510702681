.contact-form {
    position: relative;
    z-index: 5;
    margin-bottom: rem-calc(50);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(100);
    }

    &__form {
        background-color: map-deep-get($colors, "gray", "default");
        padding: rem-calc(24);

        @include media-breakpoint-up(xl) {
            padding: rem-calc(40) rem-calc(60);
        }
    }

    &__sub-text {
        margin-top: 0;
    }

    &__text {
        font-size: rem-calc(20);
        font-style: italic;
    }

    &__content {
        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(-350);
        }
    }
}
