.milestones-slider {
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        background-color: map-deep-get($colors, "gray", "default");
        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        bottom: 0;

        @include media-breakpoint-up(md) {
            top: 0;
            right: 25%;
        }
    }

    &__nav {
        width: rem-calc(35);
        display: inline-block;

        &--prev {
            margin-right: 20px;
        }

        svg {
            fill: map-deep-get($colors, "red", "default");
            width: 100%;
        }
    }

    // code vanuit de vendor en js overschrijven om te zorgen voor een fade
    /* stylelint-disable */
    .flickity-slider {
        transform: none !important;
    }
    /* stylelint-enalbe */
}

.flickity-viewport {
    transition: height 0.2s;
}