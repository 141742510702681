.cultural-value-block {
    background-color: map-deep-get($colors, "gray", "default");
    color: map-deep-get($colors, "white", "default");
    display: flex;
    flex-wrap: wrap;
    //min-height: rem-calc(340);
    min-height: 100%;
    padding: rem-calc(16) rem-calc(24);
    position: relative;
    width: 100%;
    $cultural-value-block: &;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(32) rem-calc(48);
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-size: cover;
        background-position: center;
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, transparent 0%, map-deep-get($colors, "black", "default") 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-end;
        width: 100%;
        z-index: 3;
    }

    &__title {
        transition: all 0s $transition-default-time $transition-default-smoothing;
        font-size: rem-calc(50);
        margin-bottom: 1em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(60);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(75);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(90);
        }
    }

    &__description {
        opacity: 1;
        transform: translateY(0);
        font-size: rem-calc(16);
        line-height: 1.125;
        align-self: flex-end;

        @include media-breakpoint-up(md) {
            width: 70%;
        }
    }

    &__link {
        transition: $transition-default #{$transition-default-time * 2};
        transform: translateX(-10px);
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        padding: 0 0 rem-calc(16) rem-calc(24);

        @include media-breakpoint-up(lg) {
            padding: 0 0 rem-calc(32) rem-calc(48);
        }

        @media (pointer: coarse) {
            opacity: 1;
        }

        svg {
            width: 2.5em;
            height: 2em;
            margin-right: 0.5em;
        }

        svg g {
            fill: map-deep-get($colors, "red", "default");
            transition: $transition-default;
        }
    }
}
