.video {
    $video: &;

    &--pull-top {
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            margin-top: rem-calc(-250);
        }
    }

    &__wrapper {
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(map-deep-get($colors, "black", "default"), .8);
            z-index: 1;
            transition: $transition-default;
        }

        &--playing {
            &::before {
                opacity: 0;
            }

            #{$video}__content {
                opacity: 0;
                transform: translateY(-20px);
            }

            #{$video}__play {
                opacity: 0;
                transform: translateY(20px);
            }

            #{$video}__play-button {
                opacity: 0;
            }
        }

        &--sub {
            margin-top: rem-calc(80);

            @include media-breakpoint-up(xl) {
                margin-top: rem-calc(-80);
            }
        }
    }

    &__play {
        position: absolute;
        bottom: rem-calc(10);
        left: rem-calc(10);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-deep-get($colors, "white", "default");
        text-transform: uppercase;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            bottom: rem-calc(32);
            left: rem-calc(60);
        }
    }

    &__play-button {
        background-color: map-deep-get($colors, "white", "default");
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
        transform: translate(-50%, -50%);
        transition: $transition-default;

        &:hover,
        &:focus-visible {
            background-color: rgba(255, 255, 255, .75);
        }
    }

    &__play-icon {
        border: solid transparent;
        height: 0;
        width: 0;
        pointer-events: none;
        border-left-color: map-deep-get($colors, "red", "default");
        border-width: 15px 20px 15px 25px;
    }

    &__content {
        position: absolute;
        z-index: 2;
        padding: 10px;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            padding: 30px 50px;
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__caption {
        color: map-deep-get($colors, "white", "default");
    }

    &__text {
        //margin: rem-calc(16) 0 0 0;
        margin: 1rem 0 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5em;
    }
}

@media (min-width: 1200px) {
    /* stylelint-disable */
    main.about {
        .video__wrapper {
            &:before {
                display: none;
            }
        }
    }
    /* stylelint-enable */
}

.cc-notallowed {
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px) #ffffff;
    border: 1px solid #cccccc;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

/* stylelint-disable */
#btn-revoke{
    cursor: pointer;
}
/* stylelint-enable */
