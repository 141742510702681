.pie-chart-legend {
    margin-top: 2em;

    &__item {
        display: none;

        &--active {
            display: block;
        }
    }

    &__color {
        display: inline-block;
        height: 1em;
        width: 1em;
        margin-right: 1em;
    }

    &__label {
        margin-right: 1em;
    }
}