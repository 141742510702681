.page-header {
    margin-top: 60px;
    margin-bottom: 50px;
    padding-top: 100px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
        margin-top: 120px;
        margin-bottom: 110px;
        padding-top: 120px;
    }

    &--no-bottom-margin {
        margin-bottom: 0;
    }

    &__title {
        font-size: rem-calc(36);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(90);
        }
    }

    &__caption {
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: 0.2em;
        line-height: 1.14;

        &--black {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__date {
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: 0.2em;
        margin-top: 0.75em;
        line-height: 1.14;
    }
}
