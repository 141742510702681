.office-contact-persons-slide {
    display: flex;
    min-height: 100%;
    width: 100%;
}

.office-contact-persons-slider {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        min-height: 100%;
        display: flex;
        width: 100%;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
    }

    // specifiek voor deze slider zijn overschrijvingen nodig
    /* stylelint-disable */
    .flickity-viewport {
        min-height: 100%;
        width: 100%;
    }
    /* stylelint-enable */
}

/* stylelint-disable */
#contact-persons-slider {
    position: relative;
}
/* stylelint-enable */
