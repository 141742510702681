.services-aside {
    margin-top: rem-calc(50);
    position: relative;
    z-index: 11;

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(-125);
    }

    &--not-pulled-up {
        margin-top: 3.125rem;
    }

    &__button {
        margin-bottom: 1rem;
    }
}
