.form {
    overflow: hidden;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        margin-bottom: rem-calc(24);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__label {
        font-weight: 700;
        display: block;
        margin-bottom: rem-calc(5);
    }

    /* stylelint-disable */
    &.form--loading {

        /* Button wordt gegenereerd */
        [type="submit"] {
            background-color: map-deep-get($colors, "gray", "dark");
            color: map-deep-get($colors, "gray", "default");
            pointer-events: none;
            cursor: not-allowed;
            position: relative;

            &::before {
                -webkit-animation: dots infinite 1s;
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                font-size: 1em;
                transform: translateY(-50%);
                color: black;
                width: 100%;
                text-align: center;
            }
        }
    }
    /* stylelint-enable */
}

@keyframes dots {
    0% {
        content: "\00b7 \00b7 \00b7";
    }

    20% {
        content: "\2022 \00b7 \00b7";
    }

    40% {
        content: "\2022 \2022 \00b7";
    }

    60% {
        content: "\2022 \2022 \2022";
    }

    80% {
        content: "\00b7 \2022 \2022";
    }

    100% {
        content: "\00b7 \00b7 \2022";
    }
}
