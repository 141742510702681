.header {
    $header: &;

    &__list {
        list-style-type: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        opacity: 0;
        transform: translateX(100%);
        background-color: map-deep-get($colors, "red", "default");
        transition: opacity .3s ease-in-out, transform 0s linear .3s;
        min-height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: 100%;

        @include media-breakpoint-up(md) {
            position: relative;
            opacity: 1;
            transform: translateX(0);
            background-color: transparent;
            align-items: center;
            padding-top: 0;
            overflow: inherit;
            margin-left: rem-calc(40);
            height: auto;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            margin-left: rem-calc(80);
        }
    }

    &__listItem {
        text-align: center;
        width: 100%;
        transition: transform .3s cubic-bezier(.2, 0, 0, 1), opacity .3s cubic-bezier(.2, 0, 0, 1), background-color .3s ease-in-out;
        transform: translateY(20px);
        opacity: 0;
        margin: 4vh 0;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: auto;
            opacity: 1;
            transform: translateY(0);
            border-bottom: none;
            margin: 0 rem-calc(10) 0 0;

            &--with-sub-nav {
                &.hover,
                &:hover,
                &:focus {
                    background-color: map-deep-get($colors, "red", "default");

                    #{$header}__listLink {
                        color: map-deep-get($colors, "white", "default");
                    }

                    /* stylelint-disable */
                    .header__subList {
                        opacity: 1;
                        margin-left: 0;
                        transition: opacity .3s ease-in-out, transform .3s ease-in-out, margin 0s linear;
                    }
                    /* stylelint-enable */
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__listLink {
        color: map-deep-get($colors, "white", "default");
        font-weight: 600;
        display: block;
        position: relative;
        font-size: 8vw;
        padding: 10px;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(14);

            &::before {
                content: "";
                left: 10px;
                right: 100%;
                height: rem-calc(2);
                background-color: map-deep-get($colors, "red", "default");
                display: block;
                bottom: 0;
                transition: $transition-cubic;
                position: absolute;
            }
        }

        &--with-sub-nav {
            &:hover,
            &:focus {
                background-color: map-deep-get($colors, "red", "default");
            }

            &::before {
                display: none;
            }
        }

        &--active {
            &::before {
                display: block;
                right: 10px;
            }
        }

        &:hover, &:focus {
            & + #{$header}__subList {
                opacity: 1;
                margin-left: 0;
                transform: translateY(0);
                transition: opacity .3s ease-in-out, transform .3s ease-in-out, margin 0s linear;
            }

            &::before {
                right: 10px;
            }
        }
    }
}
