.landings-template {
    /* stylelint-disable */
    .video-section {
        position: relative;
        padding-bottom: 3rem;

        @include media-breakpoint-up(xl) {
            padding-bottom: 6.25rem;
        }

        &::before {
            @include media-breakpoint-up(lg) {
                content: "";
                background-color: map-deep-get($colors, "gray", "default");
                height: 33rem;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
        }

        &__big-triangle {
            background-color: map-deep-get($colors, "white", "default");
            position: absolute;
            top: -4rem;
            left: 0;
            width: 100%;
            height: 358px;

            svg {
                transform: rotate(180deg);
                width: 100%;
                height: 360px;
            }
        }
    }
    /* stylelint-enable */

    /* stylelint-disable */
    .zigzag-section {
        padding-bottom: 3rem;

        @include media-breakpoint-up(xl) {
            padding-bottom: 6.25rem;
        }
    }
    /* stylelint-enable */

    /* stylelint-disable */
    .cases-section {
        padding-bottom: 3rem;

        @include media-breakpoint-up(xl) {
            padding-bottom: 6.25rem;
        }

        .cases-slider,
        .cases-wrapper {
            margin: 0;
        }
    }
    /* stylelint-enable */

    /* stylelint-disable */
    .cta-section {
        padding: 0 0 10rem;

        @include media-breakpoint-up(md) {
            padding: 0 0 15rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 0 25rem;
        }

        &__cta-title {
            font-size: 2rem;
            font-weight: 700;
            letter-spacing: .6px;
            line-height: 1.1;
            margin-bottom: 1.25rem;

            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 4rem;
            }
        }

        &__cta-text {
            .button {
                width: 100%;
                margin: 0 0 0.75rem;
                display: inline-flex;
                justify-content: center;

                @include media-breakpoint-up(sm) {
                    width: auto;
                    margin: 0;
                }

                &:first-child {
                    @include media-breakpoint-up(sm) {
                        width: auto;
                        margin-right: 0.75rem;
                    }
                }
            }
        }
    }
    /* stylelint-enable */
}
