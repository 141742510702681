.vatincrease {
    margin-bottom: rem-calc(16);

    &__formresult {
        background: map-deep-get($colors, "gray", "default");
    }

    &__calcform {
        padding: rem-calc(40);
    }

    &__calcformbutton {
        margin-top: rem-calc(24);
    }

    &__result {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        color: map-deep-get($colors, "white", "default");
        padding: rem-calc(40);
        height: 100%;
        background: map-deep-get($colors, "red", "default");

        &--active::after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: -29px;
            transform: translateX(-50%);
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
            border-top: 27px solid map-deep-get($colors, "red", "default");
        }
    }

    &__resultnumber {
        text-align: center;
        font-size: rem-calc(90);
        font-weight: 700;
    }

    &__resultcontact {
        width: 100%;
        display: block;
    }

    &__resultcontacttext {
        text-align: center;
        padding-bottom: rem-calc(16);
    }

    &__resultcontactbutton {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    &__contact {
        margin-top: rem-calc(16);
        padding: rem-calc(40);
        background: map-deep-get($colors, "gray", "default");
        box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.5);
    }

    &__form {
        overflow: visible;
    }
}