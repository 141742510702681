.milestones-navigation {
    border-bottom: 1px solid map-deep-get($colors, "gray", "darker");
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 15;
    $nav: &;

    @include media-breakpoint-up(md) {
        left: 60px;
        bottom: 30px;
        width: 40%;
        right: initial;
    }

    @include media-breakpoint-up(lg) {
        left: 90px;
        bottom: 80px;
    }

    @include media-breakpoint-up(xl) {
        left: 120px;
        bottom: 80px;
        width: 30%;
    }

    &__year {
        display: inline-block;
        background-color: map-deep-get($colors, "gray", "darker");
        height: rem-calc(14);
        width: rem-calc(14);
        margin-bottom: -7px;
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(map-deep-get($colors, "red", "default"), .35);
        transition: $transition-default;
        cursor: pointer;

        // code vanuit de vendor
        /* stylelint-disable */
        left: auto !important;
        position: relative !important;
        /* stylelint-enable */

        #{$nav}__year-label {
            font-size: 14px;
            font-weight: 600;
            transform: rotate(-90deg);
            transform-origin: 8.97px 8.97px;
            padding-left: rem-calc(25);
            display: inline-block;
            position: absolute;
            left: 0;
        }

        // code vanuit de vendor
        /* stylelint-disable */
        &.is-selected{
            background: map-deep-get($colors, "red", "default");
            color: map-deep-get($colors, "red", "default");
            box-shadow: 0 0 0 6px rgba(map-deep-get($colors, "red", "default"), .35);
        }
        /* stylelint-enable */
    }

    // code vanuit de vendor en js overschrijven
    /* stylelint-disable */
    .flickity-slider {
        transform: none !important;
        display: flex;
        justify-content: space-between;
    }
    /* stylelint-enable */
}