$maf_step: 1;
$maf_loops: round(100 / $maf_step);
$maf_increment: 360 / $maf_loops;
$maf_half: round($maf_loops / 2);
$maf_backcolor: #ffffff;
$maf_frontcolor: map-deep-get($colors, "red", "default");

.maf {
    &__numbers {
        height: 200px;
        width: 200px;
    }

    @include media-breakpoint-up(md) {
        &__numbers {
            height: 250px;
            width: 250px;
        }
    }

    &__progress {
        $elm: &;
        width: 100%;
        height: 100%;
        background-color: #f7b3c7;
        border-radius: 50%;
        display: inline-block;
        position: relative;

        &--subtitle {
            align-items: center;
            bottom: 30%;
            display: flex;
            font-family: $font-default;
            font-size: 12px;
            justify-content: center;
            left: 1.3rem;
            letter-spacing: 1px;
            position: absolute;
            right: 1.3rem;
        }

        &::before {
            align-items: center;
            background-color: #f7b3c7;
            border-radius: 50%;
            color: $maf_frontcolor;
            content: attr(data-euro);
            display: flex;
            font-family: $font-default;
            font-size: 2rem;
            font-style: italic;
            font-weight: 700;
            justify-content: center;
            position: absolute;
            left: 1.3rem;
            right: 1.3rem;
            top: 1.3rem;
            bottom: 1.3rem;
            /* stylelint-disable */
            transition: -webkit-transform 0.2s ease;
            transition: transform 0.2s ease;
            /* stylelint-enable */

            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }
        }

        &::after {
            background-color: $maf_frontcolor;
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
        }

        &:hover::before,
        &:focus::before {
            /* stylelint-disable */
            -webkit-transform: scale(0.9);
            /* stylelint-enable */
            transform: scale(0.9);
        }

        /* stylelint-disable */
        @for $i from 1 through $maf_loops {
            &#{$elm}[data-percent=#{'"'+ $i * $maf_step +'"'}]:after {
                @if($i < $maf_half) {
                    $next-deg: 90deg + $maf_increment * $i;
                    background-image: linear-gradient(90deg, $maf_backcolor 50%, transparent 50%, transparent), linear-gradient($next-deg, $maf_frontcolor 50%, $maf_backcolor 50%, $maf_backcolor);
                } @else {
                    $next-deg: -90deg + $maf_increment * ($i - $maf_half);
                    background-image: linear-gradient($next-deg, $maf_frontcolor 50%, transparent 50%, transparent), linear-gradient(270deg, $maf_frontcolor 50%, $maf_backcolor 50%, $maf_backcolor);
                }
            }
        }
        /* stylelint-enable */
    }
}

.ripped {
    &__top-white {
        background-image: url("../images/template/maf/ripped_header_bottom.png");
        background-position: center top;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        z-index: 3;
    }
}
