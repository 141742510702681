@include media-breakpoint-up(md) {
    .office-cta-wrapper {
        margin-top: rem-calc(30);
    }
}

.office-cta {
    position: relative;
    overflow: hidden;
    z-index: 0;

    &--wrapper {
        position: relative;
        padding: 50px 30px;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        opacity: .3;

        /* stylelint-disable */
        img {
            width: 100%;
        }
        /* stylelint-enable */
    }

    /* stylelint-disable */
    .highlight {
        &__link {
            color: #FFFFFF;
        }
    }
    /* stylelint-enable */
}
