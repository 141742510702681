.office-contact-item {
    display: block;
    margin: 1rem 0;
    color: map-deep-get($colors, "black", "default");

    &__icon {
        display: inline-block;
        height: 20px;
        width: 35px;

        svg {
            vertical-align: bottom;
        }
    }
}

.office-contact-items {
    margin: 1rem 0 5rem;
}