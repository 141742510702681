.maf__form {
    margin-bottom: 150px;
    margin-top: 150px;

    @include media-breakpoint-up(md) {
        margin-bottom: 350px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 700px;
    }

    &__content {
        /* stylelint-disable */
        h2 {
            color: map-deep-get($colors, "red", "default");
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 0.5em;
        }
        /* stylelint-enable */
    }
}
