﻿body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.intro {
    font-size: rem-calc(20);
    font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 600;
}

h1, .h1 {
    font-size: rem-calc(24);
    line-height: 1.1;
    font-weight: 700;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }
    @include media-breakpoint-up(md) {
        font-size: rem-calc(50);
    }

    &--huge {
        font-size: 10vw;
        font-weight: 700;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(56);
        }
        @include media-breakpoint-up(lg) {
            font-size: rem-calc(90);
        }
    }
}

h2, .h2 {
    font-size: rem-calc(18);
    line-height: 1.1;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(32);
    }
}

h3, .h3 {
    font-size: rem-calc(21);
    line-height: 1.4;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(28);
    }
}

h4, .h4 {
    font-size: rem-calc(24);
    line-height: 1.1;
}

h5, .h5 {
    font-size: rem-calc(20);
    font-weight: 700;
}

h6, .h6 {
    font-size: rem-calc(14);
}

a {
    color: map-deep-get($colors, "red", "default");
}

.caption {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: rem-calc(10);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(14);
        letter-spacing: 2.8px;
    }
}
