.share-sidebar {
    position: relative;
    background-color: map-deep-get($colors, "white", "default");
    padding: rem-calc(8) rem-calc(12);
    text-align: center;
    box-shadow: 0 0 rem-calc(24) 0 rgba(map-deep-get($colors, "black", "default"), .2);
    margin-top: rem-calc(32);
    display: block;
    max-width: rem-calc(250);

    @include media-breakpoint-up(xxl) {
        display: inline-block;
        position: fixed;
        z-index: 6;
        right: 0;
        top: 30vh;
        box-shadow: 0 rem-calc(22) rem-calc(24) 0 rgba(map-deep-get($colors, "black", "default"), .2);
    }

    &__title {
        color: map-deep-get($colors, "red", "default");
        display: block;
        font-weight: 600;
    }

    &__list-item {
        display: inline-block;
        width: 49%;

        @include media-breakpoint-up(xxl) {
            display: block;
            width: 100%;
        }
    }

    &__list-link {
        display: block;
        text-align: center;
        color: map-deep-get($colors, "black", "default");
        padding: rem-calc(12);
        transition: $transition-default;

        &--whatsapp {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &:hover,
        &:focus {
            color: map-deep-get($colors, "red", "default");

            svg path {
                fill: map-deep-get($colors, "red", "default");
            }
        }
    }

    svg {
        width: rem-calc(18);
        height: rem-calc(18);
        margin: 0 auto;
    }

    svg path {
        transition: $transition-default;
    }

    &__text {
        display: block;
        line-height: 1;
        font-size: rem-calc(14);
    }
}
