.testimonials-navigation-control {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    background: map-deep-get($colors, "gray", "default");
    justify-content: center;
    align-content: center;
    display: flex;

    svg {
        width: 70%;
    }
}