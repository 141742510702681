.pagination {
    position: relative;
    z-index: 2;
    margin: rem-calc(32) 0;

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 rem-calc(-4);
    }

    &__list-item {
        align-self: center;
        display: inline-block;
        margin: rem-calc(8) rem-calc(4);

        &--control {
            display: block;
            width: 100%;

            @include media-breakpoint-up(md) {
                display: inline-block;
                width: auto;
            }
        }

        @include media-breakpoint-up(md) {
            margin: 0 rem-calc(4);
        }
    }

    &__control-link {
        color: map-deep-get($colors, "gray", "darker");
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition-default;

        svg {
            width: rem-calc(30);
            fill: map-deep-get($colors, "red", "default");
        }

        &--prev {
            svg {
                margin-right: rem-calc(16);
            }
        }

        &--next {
            svg {
                margin-left: rem-calc(16);
            }
        }

        &--inactive {
            display: none;
        }

        &:hover,
        &:focus {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__list-link {
        border-radius: $border-radius;
        padding: 0 5px;
        height: rem-calc(23);
        min-width: rem-calc(23);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        color: map-deep-get($colors, "black", "default");
        transition: $transition-default;

        &--active {
            pointer-events: none;
            border-color: map-deep-get($colors, "red", "default");
        }

        &:hover,
        &:focus {
            border-color: map-deep-get($colors, "gray", "dark");
        }
    }
}