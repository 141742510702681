.dual-video {
    position: relative;
    z-index: 5;

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(32);
    }

    $dual-video: &;

    &--pull-top {
        margin-top: rem-calc(-100);

        @include media-breakpoint-up(xl) {
            margin-top: -36vh;
        }
    }

    &__video {
        margin-bottom: rem-calc(32);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(64);

            &--dom {
                margin-top: 12vh;
            }
        }
    }
}