.cultural-value-slide {
    min-height: 100%;
    display: flex;
    padding-right: 5%;
    transition: opacity $transition-default-time $transition-default-smoothing;
    width: 100%;
    opacity: 1;
    $cultural-value-block: &;

    @include media-breakpoint-up(md) {
        width: 90%;
    }

    @include media-breakpoint-up(lg) {
        width: 75%;
    }

    @include media-breakpoint-up(xl) {
        width: 70%;
    }

    /* stylelint-disable */

    .cultural-value-block {

        &__title {
            transition: all $transition-default-time $transition-default-time $transition-default-smoothing;
            margin-bottom: 1em;
        }

        &__description {
            transition: all $transition-default-time $transition-default-time $transition-default-smoothing;
            opacity: 1;
            transform: translateY(0);
        }
    }
    /* stylelint-enable */
}

/* stylelint-disable */
.flickity-slider .cultural-value-slide:not(.is-selected) {
    .cultural-value-block {
        opacity: 0.5;

        &__title {
            //margin-bottom: 0;
        }

        &__description {
            opacity: 0;
            transform: translateY(20%);
        }
    }
}
/* stylelint-enable */
