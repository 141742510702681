.reviews {
    &__all-reviews-button {
        margin-top: rem-calc(16);
        text-align: center;
    }
}

.reviews-intro {
    margin-bottom: rem-calc(16);
    background: map-deep-get($colors, "gray", "default");

    &__text {
        padding: rem-calc(40);
    }

    &__title {
        margin-bottom: 1.8em;
        text-transform: uppercase;
        letter-spacing: .175em;
        color: map-deep-get($colors, "red", "default");
    }

    &__amount {
        margin-bottom: 1.2em;
    }

    &__paragraph {
        margin-bottom: 1.2em;
    }

    &__total-score {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: rem-calc(40);
        color: map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "red", "default");
    }

    &__total-score-number {
        font-size: rem-calc(90);
        font-weight: 700;
    }

    &__total-score-stars {
        svg {
            width: 16px;
        }
    }
}

.review {
    margin-bottom: rem-calc(16);
    padding: rem-calc(40);
    background: map-deep-get($colors, "gray", "default");

    &__name {
        margin-bottom: 1.8em;
        text-transform: uppercase;
        letter-spacing: .175em;
        color: map-deep-get($colors, "red", "default");
    }

    &__stars {
        color: map-deep-get($colors, "red", "default");
        text-align: right;

        svg {
            width: 16px;
        }
    }

    &__description {
        margin-bottom: rem-calc(16);
    }

    &__question-and-answer {
        margin-bottom: rem-calc(16);
    }
}