.team-slider-controls {
    &__control {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: rem-calc(-40);

        @include media-breakpoint-up(md) {
            top: inherit;
            bottom: 0;
        }
        @include media-breakpoint-up(xl) {
            right: rem-calc(20);
            bottom: 30%;
            width: rem-calc(80);
            height: rem-calc(80);
        }
        @include media-breakpoint-up(xxl) {
            right: rem-calc(-40);
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }
}