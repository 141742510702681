.header {
    $header: &;

    &__subList {
        position: relative;
        list-style-type: none;
        padding: 10px;
        color: map-deep-get($colors, "black", "default");
        min-width: rem-calc(200);
        text-align: center;
        background-color: map-deep-get($colors, "red", "default");
        font-size: rem-calc(14);

        &:hover, &:focus {
            opacity: 1;
            margin-left: 0;
            transition: opacity .3s ease-in-out, transform .3s ease-in-out, margin 0s linear;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            opacity: 0;
            top: 100%;
            margin: 0 0 0 9999px;
            transition: opacity .3s ease-in-out, transform .3s ease-in-out, margin 0s linear .3s;
        }
    }

    &__subListItem {
        transform: translateY(20px);
        opacity: 0;
        transition: transform .3s cubic-bezier(.2, 0, 0, 1), opacity .3s cubic-bezier(.2, 0, 0, 1);

        @include media-breakpoint-up(md) {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__subListLink {
        padding: rem-calc(8);
        display: block;
        width: 100%;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            text-align: left;
            opacity: 1;
            transform: translateY(0);
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }
}