.counter {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding-bottom: 40px;
    padding-top: 35px;

    @include media-breakpoint-up(lg) {
        padding-top: 95px;
        padding-bottom: 75px;
    }

    &--left {
        border-bottom: 1px solid map-deep-get($colors, "gray", "dark");

        @include media-breakpoint-up(md) {
            border-bottom: 0;
            border-right: 1px solid map-deep-get($colors, "gray", "dark");
        }
    }

    &__title {
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
        font-size: rem-calc(14);
        margin-bottom: 1.2em;
    }

    &__content {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    &__value {
        font-size: rem-calc(30);
        line-height: 1.7;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(50);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(75);
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(90);
        }
    }

    &__target {
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(14);
    }
}