.focus-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    z-index: 10;

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }

    &__logo {
        @include media-breakpoint-up(md) {
            margin-right: 12%;
        }

        svg {
            height: rem-calc(32);
            width: auto;
        }
    }

    &__button {
        svg {
            fill: map-deep-get($colors, "white", "default");
            width: rem-calc(25);
            height: rem-calc(25);
        }
    }
}