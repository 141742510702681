.partners {
    background-color: map-deep-get($colors, "gray", "default");
    padding: 20px;

    &__title {
        margin-bottom: rem-calc(40);
    }

    &__list-link {
        background-color: map-deep-get($colors, "white", "default");
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem-calc(20);
        color: map-deep-get($colors, "black", "dark");
        box-shadow: 0 2px 14px 0 rgba(map-deep-get($colors, "black", "dark"), .12);
        transition: $transition-default;

        svg {
            width: rem-calc(24);
            height: rem-calc(18);
            margin-left: rem-calc(20);
            fill: map-deep-get($colors, "red", "default");
            transition: $transition-default;
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "lighter");

            svg {
                transform: translateX(10px);
            }
        }
    }
}
