.cultural-values-slider-controls {
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;

    &__control {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: rem-calc(-25);
        margin-right: rem-calc(-25);

        @include media-breakpoint-up(xl) {
            width: rem-calc(80);
            height: rem-calc(80);
            margin-top: rem-calc(-40);
            margin-right: rem-calc(-40);
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "red", "light");
        }
    }
}