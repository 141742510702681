.milestone-slide {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: $transition-default;
    width: 100%;
    $slide: &;

    &__content {
        opacity: 0;
        transition: all $transition-default-time $transition-default-time $transition-default-smoothing;
        display: inline-block;
        padding: 75px 25px 100px;
        width: 100%;
        order: 2;

        @include media-breakpoint-up(md) {
            padding: 50px 25% 100px 60px;
            order: 1;
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            padding: 60px 30% 150px 90px;
            order: 1;
            width: 75%;
        }

        @include media-breakpoint-up(xl) {
            padding: 80px 30% 150px 120px;
        }
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 0.75em;
    }

    &__description {
        line-height: 1.5em;
        margin-bottom: 2rem;
    }

    &__image {
        display: inline-block;
        opacity: 0;
        transform: translate(0, -25%);
        width: 96%;
        order: 1;
        margin: 0 auto -50px;

        @include media-breakpoint-up(md) {
            margin-left: -20%;
            margin-top: 60px;
            margin-bottom: 60px;
            transform: translate(25%, 0);
            order: 2;
            width: 45%;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
    }

    // vendor classnames en js overschrijving
    /* stylelint-disable */
    left: 0 !important;
    &.is-selected {
        opacity: 1;
        z-index: 2;

        #{$slide} {
            &__image {
                transition: all $transition-default-time #{$transition-default-time * 2} $transition-default-smoothing;
                opacity: 1;
                transform: translate(0, 0);
            }

            &__content {
                opacity: 1;
            }
        }
    }
    /* stylelint-enalbe */
}
