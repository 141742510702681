.pattern {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    svg {
        display: block;
        margin-bottom: rem-calc(64);
        width: 50%;
        float: right;
        overflow: visible;

        @include media-breakpoint-up(xl) {
            width: auto;
            float: none;
        }

        &:last-of-type {
            display: none;

            @include media-breakpoint-up(xl) {
                display: block;
            }
        }
    }

    &--red {
        svg g {
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &--specialism {
        z-index: 10;
    }

    &--maf {
        top: 70vh;
        z-index: 15;

        svg g {
            fill: map-deep-get($colors, "red", "lighter");
        }
    }
}
