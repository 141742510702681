.testimonial-nav {
    width: 100%;
    background-color: map-deep-get($colors, "gray", "default");
    padding: 20px 15px;
    min-height: 100%;
    $elm: &;

    @include media-breakpoint-up(md) {
        padding: 40px 30px;
    }

    @include media-breakpoint-up(md) {
        width: 50%;
    }

    @include media-breakpoint-up(xl) {
        width: 33.333%;
    }

    &__name {
        color: map-deep-get($colors, "red", "default");
        text-transform: uppercase;
    }

    &__function {
        color: map-deep-get($colors, "black", "default");
    }

    /* stylelint-disable */
    &.is-selected {
        background: map-deep-get($colors, "red", "default");

        #{$elm}__name {
            color: map-deep-get($colors, "white", "default");
        }
    }
    /* stylelint-enable */
}