.maf__blogs {
    padding-top: 150px;

    &__title {
        color: map-deep-get($colors, "red", "default");
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}
