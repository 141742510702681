.main {
    overflow: hidden;
    max-width: 100%;
    min-height: 100vh;

    &__inner {
        position: relative;
        z-index: 5;
    }
}

.grid-container {
    padding: 0 20px;

    @include media-breakpoint-up(xl) {
        padding: 0 30px;
    }
    @include media-breakpoint-up(xxl) {
        padding: 0;
    }
}

.h-100 {
    height: 100%;
}