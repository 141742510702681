.collaboration-card {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-top: rem-calc(20);
    color: map-deep-get($colors, "black", "default");
    $elm: &;

    &__content {
        padding-left: 20px;
    }

    &__title {
        font-size: rem-calc(14);
        margin-bottom: rem-calc(10);
        display: block;
    }

    &__text {
        margin: 0;
    }

    &__image {
        width: rem-calc(120);
        height: auto;
    }

    &__read-more {
        color: map-deep-get($colors, "black", "default");
        margin-top: rem-calc(20);
        display: inline-flex;
        align-items: center;

        svg {
            width: rem-calc(24);
            height: 1em;
            fill: map-deep-get($colors, "red", "default");
            margin-right: rem-calc(10);
        }
    }

    &:hover,
    &:focus {
        #{$elm}__read-more {
            color: map-deep-get($colors, "red", "default");
        }
    }
}