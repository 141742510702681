.section-caption {
    margin-bottom: rem-calc(40);

    &--small {
        margin-bottom: rem-calc(20);
    }

    &__text {
        margin: rem-calc(8) 0 0 0;
    }
}
