.overlay-video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 99999;

    &__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__content {
        position: relative;
    }

    &__close {
        background-color: map-deep-get($colors, "white", "default");
        border-radius: 50%;
        cursor: pointer;
        padding: 11px 13px;
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        z-index: 2;

        @include media-breakpoint-up(xl) {
            right: 0;
            top: 0;
            transform: translate(50%, -50%);
        }

        svg {
            height: 1.125rem;
            width: 1.125rem;
        }
    }

    &__wrapper {
        background-color: map-deep-get($colors, "white", "default");
        max-height: calc(100vh - 1.5rem);
        max-width: 1200px;
        overflow: hidden;
        width: calc(100vw - 1rem);

        @include media-breakpoint-up(md) {
            width: calc(100vw - 2em);
        }

        @include media-breakpoint-up(xl) {
            width: 100vw;
        }

        p:last-child {
            margin: 0;
        }
    }

    /* stylelint-disable */
    .video {
        &__wrapper {
            &::before {
                background-color: rgba(map-deep-get($colors, "black", "default"), .3);
            }
        }

        &__play-button {
            background-color: map-deep-get($colors, "red", "default");
            color: map-deep-get($colors, "white", "default");
        }

        &__play-icon {
            border-left-color: map-deep-get($colors, "white", "default");
        }
    }
    /* stylelint-enable */
}
