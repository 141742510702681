.contact-socials {
    margin-bottom: rem-calc(50);
    $elm: &;

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(100);
    }

    &__item {
        line-height: 2.5em;
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        width: 35px;
    }

    &__icons {
        margin: 20px -5px auto;

        #{$elm}__icon {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    svg {
        fill: map-deep-get($colors, "black", "default");
        width: rem-calc(24);
        height: rem-calc(24);
    }
}