.discipline-card {
    color: map-deep-get($colors, "black", "default");
    background-color: map-deep-get($colors, "gray", "default");
    display: block;
    position: relative;
    z-index: 2;
    padding: 30px 40px 20px 30px;
    margin-bottom: map-deep-get($spacing, "default", 4);
    transition: $transition-default;
    $discipline-card: &;

    @media (pointer: coarse) {
        color: map-deep-get($colors, "white", "default");
        background-color: map-deep-get($colors, "black", "default");
    }

    @include media-breakpoint-up(xl) {
        padding: 30px 80px 20px 30px;
    }

    &__bg-image {
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 2;

        // Lazyloading opacity transformation. Vendor styling transitions opacity to 1.
        // This instance it needs to change to opacity: 0, and only show it on hover
        /* stylelint-disable */
        &.b-loaded {
            opacity: 0;

            @media (pointer: coarse) {
                opacity: .6;
            }
        }
        /* stylelint-enable */
    }

    &__bg-color {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
        background: linear-gradient(180deg, transparent 0%, map-deep-get($colors, "black", "default") 85%);
        opacity: 0;
        transition: $transition-default;

        @media (pointer: coarse) {
            opacity: 1;
        }
    }

    &__content {
        position: relative;
        height: rem-calc(245);
        z-index: 2;
    }

    &__caption {
        color: map-deep-get($colors, "red", "default");
        transition: $transition-default;

        @media (pointer: coarse) {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__title {
        margin-top: map-deep-get($spacing, "default", 1);
    }

    &__read-more {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg g {
            fill: map-deep-get($colors, "red", "default");
            transition: $transition-default;

            @media (pointer: coarse) {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__read-more-text {
        margin-left: rem-calc(16);
        color: map-deep-get($colors, "red", "default");
        opacity: 0;
        transform: translateX(-10px);
        transition: $transition-default;

        @media (pointer: coarse) {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &:hover,
    &:focus {
        color: map-deep-get($colors, "white", "default");
        background-color: map-deep-get($colors, "black", "default");

        #{$discipline-card}__caption {
            color: map-deep-get($colors, "white", "default");
        }

        #{$discipline-card}__bg-image {
            opacity: .6;
        }

        #{$discipline-card}__bg-color {
            opacity: 1;
        }

        #{$discipline-card}__read-more-text {
            opacity: 1;
            transform: translateX(0);
        }

        #{$discipline-card}__read-more {
            svg g {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}