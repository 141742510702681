.images-slider {
    position: relative;
    overflow: hidden;
    //max-width: rem-calc(560);
    width: 100%;
    margin: 0 auto;

    &__text {
        position: absolute;
        top: 1.563rem;
        left: 1.563rem;
        color: map-deep-get($colors, "white", "default");
        font-weight: 700;
        z-index: 2;

        @include media-breakpoint-up(sm) {
            font-size: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 1.75rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 2.5rem;
        }
    }

    &__list {
        position: absolute;
        //bottom: rem-calc(80);
        bottom: rem-calc(25);
        left: rem-calc(25);
        z-index: 2;
        pointer-events: none;
    }

    &__button {
        position: absolute;
        //bottom: rem-calc(80);
        bottom: rem-calc(25);
        left: rem-calc(25);
        z-index: 2;
    }

    &__list-item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        color: map-deep-get($colors, "white", "default");
        font-weight: 600;
        font-size: rem-calc(20);
        margin-top: rem-calc(5);

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(28);
        }

        svg {
            width: 1em;
            height: 1em;
            fill: map-deep-get($colors, "validation", "valid");
            margin-right: rem-calc(10);
        }
    }

    &__list-item-content {
        flex: 1;
    }

    &__slider {
        position: relative;
    }

    &__slide {
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(180deg, transparent 0%, map-deep-get($colors, "black", "dark") 100%);
            z-index: 1;
            pointer-events: none;
        }
    }

    &__img {
        width: 100%;
    }

    /* stylelint-disable */
    .flickity-page-dots {
        position: relative;
        bottom: 0;
        margin-top: rem-calc(32);

        .dot {
            background-color: map-deep-get($colors, "white", "default");
            opacity: 1;

            &.is-selected {
                background-color: map-deep-get($colors, "black", "default");
            }
        }
    }

    /* stylelint-enable */
}
