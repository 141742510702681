.maf__actions {
    padding-top: 150px;

    &__title {
        color: map-deep-get($colors, "red", "default");
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 70px;
    }
}

.maf-action {
    position: relative;
    z-index: 2;
    display: block;
    margin-bottom: 1rem;
    width: 100%;
    background-color: map-deep-get($colors, "gray", "default");
    color: map-deep-get($colors, "black", "default");
    $maf-action: &;

    &__image {
        width: 100%;
        padding-top: 74.5%;
        background-position: center;
        background-size: cover;
        background-color: map-deep-get($colors, "red", "default");
    }

    &__content {
        height: rem-calc(250);
        text-align: center;
        padding: rem-calc(36) rem-calc(24);
        position: relative;
    }

    &__icon {
        width: rem-calc(28);
        height: rem-calc(28);
        transform: rotate(-45deg);
        background-color: map-deep-get($colors, "white", "default");
        position: absolute;
        top: rem-calc(-14);
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            border: solid transparent;
            height: 0;
            width: 0;
            pointer-events: none;
            border-top-color: map-deep-get($colors, "red", "default");
            border-width: 10px 6px 0;
            display: block;
            transform: rotate(45deg);
            transition: $transition-default;
        }
    }

    &__read-more {
        position: absolute;
        bottom: rem-calc(32);
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: rem-calc(32);
            height: auto;
            margin-right: rem-calc(8);
        }

        svg g {
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__read-more-text {
        overflow: hidden;
        white-space: nowrap;
        width: 0;
        transition: $transition-default;
    }

    &:hover,
    &:focus {
        #{$maf-action}__read-more-text {
            width: rem-calc(200);
        }

        #{$maf-action}__icon {
            &::after {
                margin-top: 6px;
                margin-left: -6px;
            }
        }
    }
}
