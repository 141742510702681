.scroll-spy-wrapper {
    position: relative;
}

.scroll-spy {
    position: absolute;
    top: rem-calc(240);
    z-index: 8;
    display: inline-block;

    @include media-breakpoint-up(xxl) {
        left: rem-calc(60);
    }

    &__item {
        display: block;
    }

    &__link {
        text-transform: uppercase;
        color: map-deep-get($colors, "black", "default");
        padding: 2px 4px;
        font-size: rem-calc(10);
        letter-spacing: 3px;
        font-weight: 600;

        &--active {
            background-color: map-deep-get($colors, "red", "default");
            color: map-deep-get($colors, "white", "default");
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(12);
        }
    }

    &--active {
        position: fixed;
        top: rem-calc(110);
    }
}
