.accordion {
    padding-bottom: 20px;
    $accordion: &;

    &__panel {
        padding-bottom: 10px;
    }

    &__panel-title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 28px 20px;
        margin: 0;
        color: map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "red", "default");
        transition: $transition-default;

        &:hover {
            background: map-deep-get($colors, "red", "light");
        }
    }

    &__panel-content {
        background: map-deep-get($colors, "gray", "default");
        overflow: hidden;
        transition: $transition-default;
    }

    &__panel-content-inner {
        padding: 20px;

        /* stylelint-disable */
        b {
            font-weight: 600;
        }

        ol {
            padding-left: rem-calc(16);
        }

        ul li:before {
            transform: translateX(-6px);
        }
        /* stylelint-enable */
    }

    &__panel-status-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: rem-calc(30);
        height: rem-calc(30);
        border-radius: 50%;
        background: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        svg {
            width: rem-calc(14.5);
            height: rem-calc(14.5);
        }

        svg path {
            fill: map-deep-get($colors, "red", "default");
        }

        &--active {
            transform: rotate(180deg);
            background: map-deep-get($colors, "black", "default");

            svg path {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}
