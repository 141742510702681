.zigzag-content {
    &__row {
        &:not(&:nth-last-child(1)) {
            margin-bottom: 2rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 5rem;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 7.5rem;
            }
        }
    }

    &__col {
        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__image-wrapper {
        min-height: 500px;

        @include media-breakpoint-up(lg) {
            min-height: 600px;
        }
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        min-height: inherit;
    }

    &__text {
        /* stylelint-disable */
        h2, h3, h4 {
            @extend .h1
        }
        /* stylelint-enable */
    }
}
