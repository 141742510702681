.highlight {
    position: relative;
    margin-bottom: rem-calc(10);
    padding: 20px 10px;
    color: map-deep-get($colors, "black", "default");
    background: map-deep-get($colors, "white", "default");
    $block: &;

    @include media-breakpoint-up(md) {
        padding: 25px 15px;
    }

    @include media-breakpoint-up(lg) {
        padding: 30px 20px;
    }

    @include media-breakpoint-up(xl) {
        padding: 35px 25px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 40px 30px;
    }

    &:hover {
        #{$block}__link {
            svg {
                margin-right: 0.2em;
            }
        }
    }

    &--mobile {
        @include media-breakpoint-down(sm) {
            margin-top: -120px;
        }
    }

    &__icon {
        position: absolute;
        top: -10px;
        right: 30px;

        svg path {
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__title {
        display: inline-block;
        margin-bottom: rem-calc(16);
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 0.2em;
        color: map-deep-get($colors, "red", "default");
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
        display: inline-flex;
        align-items: center;

        svg {
            transition: $transition-default;
            width: rem-calc(35);
            margin-right: 0.5em;
            height: 1em;
        }

        svg path {
            fill: map-deep-get($colors, "red", "default");
        }
    }

    &__overlay-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
