.content-section {
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
        &--pull {
            margin-top: rem-calc(-130);
        }
    }
}
