.maf__content {
    margin-top: -130px;

    &__well {
        background-color: #f0f0f0;
        padding: 50px 80px;
        position: relative;
        z-index: 16;

        /* stylelint-disable */
        .rich-text-area {
            h2 {
                color: map-deep-get($colors, "red", "default");
                font-style: italic;
                font-weight: 700;
                margin-bottom: 30px;
            }
        }
        /* stylelint-enable */

        &--red {
            background-color: #f7b3c7;

            /* stylelint-disable */
            .rich-text-area {
                h2 {
                    color: map-deep-get($colors, "white", "default");
                    font-size: 24px;
                }
            }
            /* stylelint-enable */
        }
    }
}
