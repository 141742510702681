.services-wrapper {
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(50);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(100);
    }

    &--office {
        margin-top: 3.125rem;
    }

    &__header {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 3.5rem;
        }
    }
}
